import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Footer from '../../components/Footer';
import { saveApplication } from '../../redux/slices/ApplicationSlice';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

/**
 * A functional component that renders a position page for a job application.
 * It displays the position details, a form for applicants to fill in their personal and educational information, 
 * and allows them to upload their resume.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.positions - An array of position objects.
 * @return {JSX.Element} The JSX element representing the position page.
 */
const PositionPage = ({ positions }) => {
  const dispatch = useDispatch();
  const { positionId } = useParams();
  const navigate = useNavigate();
  const position = positions.find((pos) => pos.id === positionId);
  const [application, setApplication] = useState({
    position: position?.title || '',
    name: '',
    email: '',
    school: '',
    major: '',
    gpa: '',
    resume: null,
  });
  const [fileName, setFileName] = useState('');

  if (!position) {
    return <p>Position not found. Please check the available careers.</p>;
  }

  /**
   * Handles changes to input fields in the application form.
   *
   * @param {Object} e - The event object triggered by the input change.
   * @return {void}
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplication((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Handles changes to the file input field, updating the application state with the selected file.
   *
   * @param {Object} e - The event object triggered by the file input change.
   * @return {void}
   */
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setApplication((prev) => ({ ...prev, resume: file }));
    logEvent(analytics, 'file_upload', { file_name: file.name });
  };

  /**
   * Handles the submission of the application form.
   *
   * @param {Object} e - The event object triggered by the form submission.
   * @return {void}
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!application.resume) {
      alert('Please upload your resume');
      return;
    }
    console.log("Application submitted:", application);

    dispatch(saveApplication(application))
      .then((result) => {
        if (result.type === 'application/saveApplication/fulfilled') {
          logEvent(analytics, 'application_submitted', { position: application.position });
          navigate('/thankyouforjoiningourteam');
        }
      })
      .catch((error) => {
        console.error('Failed to save the application.', error);
        logEvent(analytics, 'application_submission_failed', { error: error.message });
      });
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="container mx-auto px-4 py-8">
        <button
          onClick={() => {
            navigate(-1);
            logEvent(analytics, 'button_click', { button_name: 'Back to all positions' });
          }}
          className="text-text mb-6 flex items-center hover:animate-zoomIn hover:text-webDarker"
        >
          <FontAwesomeIcon icon={faAngleLeft} className="mr-2" /> Back to all positions
        </button>
        <h1 className="text-3xl font-bold mb-4">{position.title}</h1>
        <p className="text-lg mb-6">{position.description}</p>
        <h4 className="text-xl font-semibold mb-4">Qualifications:</h4>
        <p className="text-lg mb-8">{position.qualifications}</p>
        <form onSubmit={handleSubmit} className="space-y-12">
          <div>
            <h2 className="text-2xl font-semibold mb-6">Personal Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={application.name}
                  onChange={handleInputChange}
                  placeholder="Full Name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={application.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-6">Education</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="form-group">
                <input
                  type="text"
                  name="school"
                  value={application.school}
                  onChange={handleInputChange}
                  placeholder="School"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="major"
                  value={application.major}
                  onChange={handleInputChange}
                  placeholder="Major"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                />
              </div>
              <div className="form-group">
                <select
                  name="gpa"
                  value={application.gpa}
                  onChange={handleInputChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                >
                  <option value="">Select GPA</option>
                  <option value="4.0">4.0</option>
                  <option value="3.9">3.9</option>
                  <option value="3.8">3.8</option>
                  <option value="3.7">3.7</option>
                  <option value="3.6">3.6</option>
                  <option value="3.5">3.5</option>
                  <option value="3.4">3.4</option>
                  <option value="3.3">3.3</option>
                  <option value="3.2">3.2</option>
                  <option value="3.1">3.1</option>
                  <option value="3.0">3.0</option>
                  <option value="2.9<">Below 3.0</option>
                </select>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-6">Resume/CV</h2>
            <div className="form-group">
              <button
                type="button"
                onClick={() => document.getElementById('resume-upload').click()}
                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-hoverColor transition-colors"
              >
                Upload Resume
              </button>
              <input
                type="file"
                id="resume-upload"
                name="resume"
                onChange={handleFileChange}
                className="hidden"
              />
              {fileName && (
                <div className="mt-4 text-sm">
                  <strong>Uploaded File:</strong>
                  <p className="mt-2 text-gray-700">{fileName}</p>
                </div>
              )}
            </div>
          </div>

          <div className="text-center mt-8">
            <button
              type="submit"
              className="px-8 py-3 bg-primary text-white rounded-lg hover:bg-hoverColor transition-colors"
            >
              Submit Application
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(PositionPage);
