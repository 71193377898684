import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBars, faUserAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CartModal from '../CartModal';
import FlyoutLink from './FlyoutLink';
import logo2 from '../../assets/img/new-ALBUddy-removebg.png';
import DiscountMessage from '../DiscountMessage/DiscountMessage';

/**
 * Renders the About Us menu component.
 *
 * @return {JSX.Element} The About Us menu JSX element
 */
const AboutUsMenu = () => (
  <div className="bg-white rounded-lg shadow-lg w-40">
    <a href="/#whyUs" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Why Us</a>
    <a href="/#history" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Our Story</a>
    <a href="/FAQ" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">FAQ</a>
  </div>
);

/**
 * Renders the Careers menu component.
 *
 * @return {JSX.Element} The Careers menu JSX element
 */
const CareersMenu = () => (
  <div className="bg-white rounded-lg shadow-lg w-48">
    <a href="/careers/marketing" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Marketing</a>
    <a href="/careers/sales" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Sales</a>
    <a href="/careers/engineering" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Engineering</a>
    <a href="/careers/instructor" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Instructors</a>
  </div>
);

/**
 * A functional component representing the application's navigation bar.
 *
 * It handles user state, scrolling, and mobile menu interactions.
 *
 * @return {JSX.Element} The JSX element representing the navigation bar.
 */
function Navbar() {
  const user = useSelector(state => state.user.user);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const navbarMenuRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleCart = useCallback(() => {
    setIsCartVisible(prev => !prev);
  }, []);

  const handleScroll = useCallback(() => {
    setScrolled(window.scrollY > 0);
  }, []);

  const onCheckout = useCallback(() => {
    if (location.pathname === '/checkout') {
      toggleCart();
    } else {
      navigate('/checkout');
    }
  }, [location.pathname, navigate, toggleCart]);

  const cartItemCount = useSelector(state => state.cart.totalCount);

  const handleClickOutside = useCallback((event) => {
    if (isMobileMenuOpen && navbarMenuRef.current && !navbarMenuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = useCallback((event) => {
    event.stopPropagation();
    setIsMobileMenuOpen(prev => !prev);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleScroll, handleClickOutside]);

  return (
    <>
      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${scrolled ? 'bg-white shadow-lg' : 'bg-transparent'} backdrop-blur-md h-auto flex flex-col items-center justify-between px-4 pb-2`}>
        <div className="w-full flex justify-center items-center bg-transparent p-5">
          <DiscountMessage />
        </div>
        <div className="flex items-center justify-between w-full mx-auto max-w-full">
          <div className="flex items-center">
            <a href="/" className="flex-shrink-0 ml-4">
              <img src={logo2} alt="ALBuddy" className="h-24 w-auto" />
            </a>
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <div className="hidden md:flex items-center space-x-12" ref={navbarMenuRef}>
              <a href="/courses" className="text-base text-textGray hover:text-webDarker">Courses</a>

              <FlyoutLink href="/" FlyoutContent={AboutUsMenu}>
                About
              </FlyoutLink>

              <a href="/blogs" className="text-base text-textGray hover:text-webDarker">Blogs</a>

              <FlyoutLink href="/careers" FlyoutContent={CareersMenu}>
                Careers
              </FlyoutLink>

              <a href="/portal" className="text-base bg-transparent p-2 pl-4 pr-4 rounded-full text-webDarker hover:text-hoverColor flex items-center">
                <FontAwesomeIcon icon={faUserAlt} className="mr-2" />
                {user?.name ? `${user.name}'s Portal` : 'Login'}
              </a>

              <div className="relative" onClick={toggleCart}>
                <FontAwesomeIcon icon={faShoppingCart} className="text-xl text-gray-800 hover:text-webDarker" />
                {cartItemCount > 0 && (
                  <span className="absolute -top-2 -right-2 bg-webDarker text-white rounded-full text-xs px-2 py-1">
                    {cartItemCount}
                  </span>
                )}
              </div>
            </div>

            <div className={`md:hidden cursor-pointer text-xl transition-transform duration-300 ${isMobileMenuOpen ? 'rotate-180' : 'rotate-0'}`}  onClick={toggleMobileMenu}>
              <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
            </div>
          </div>
        </div>
      </nav>

      <div className={`fixed top-20 left-0 right-0 z-40 md:hidden transition-transform duration-300 ${isMobileMenuOpen ? 'translate-y-0' : '-translate-y-full'} bg-white backdrop-blur-md h-screen w-screen flex flex-col items-center space-y-6 pt-24 pb-10`}>
        <a href="/courses" className="text-lg text-gray-800 hover:text-webDarker">Courses</a>
        <a href="/#about" className="text-lg text-gray-800 hover:text-webDarker">About</a>
        <a href="/blogs" className="text-lg text-gray-800 hover:text-webDarker">Blogs</a>
        <a href="/careers" className="text-lg text-gray-800 hover:text-webDarker">Careers</a>
        <a href="/portal" className="bg-webDarker text-white py-2 px-4 rounded-full hover:text-webDarker">
          {user?.name ? `${user.name}'s Portal` : 'Login'}
        </a>
        <div className="relative" onClick={toggleCart}>
          <FontAwesomeIcon icon={faShoppingCart} className="text-xl text-gray-800 hover:text-webDarker" />
          {cartItemCount > 0 && (
            <span className="absolute -top-2 -right-2 bg-webDarker text-white rounded-full text-xs px-2 py-1">
              {cartItemCount}
            </span>
          )}
        </div>
      </div>

      <CartModal isVisible={isCartVisible} onClose={toggleCart} onCheckout={onCheckout} />
    </>
  );
}

export default React.memo(Navbar);
