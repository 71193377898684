import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlogPreview from '../../components/BlogPreview';
import { fetchBlogPosts } from '../../redux/slices/blogSlice';
import Footer from '../../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle, faSmileBeam } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import FeaturedPost from '../../components/FeaturedPost';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

/**
 * Renders the Blog page.
 *
 * @return {JSX.Element} The Blog page component.
 */
function Blog() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog.posts);
  const status = useSelector((state) => state.blog.status);
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    dispatch(fetchBlogPosts({ numToFetch: 10 }));
    logEvent(analytics, 'page_view', { page_title: 'Blog Page' });
  }, [dispatch]);

  const handleSeeAllClick = () => {
    setSeeAll(true);
    logEvent(analytics, 'button_click', { button_name: 'See All', location: 'Blog Page' });
  };

  const handleBlogClick = (post) => {
    logEvent(analytics, 'blog_click', { post_id: post.id, post_title: post.title });
  };

  const results = posts;
  const mostRecentPost = posts.length > 0 ? posts[0] : null;
  const filteredResults = results.filter(post => post.id !== (mostRecentPost && mostRecentPost.id));
  const featuredPosts = filteredResults.slice(0, 3);
  const featuredPostsIds = new Set(featuredPosts.map(post => post.id));
  const remainingPosts = seeAll ? results : filteredResults.slice(3).filter(post => !featuredPostsIds.has(post.id));

  let content;
  if (status === 'loading') {
    content = (
      <div className="flex justify-center items-center py-16 text-webDarker">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  } else if (status === 'succeeded') {
    content = remainingPosts.length > 0 ? remainingPosts.map((post) => (
      <div onClick={() => handleBlogClick(post)} key={post.id} className="cursor-pointer">
        <BlogPreview
          id={post.id}
          tags={post.tags}
          title={post.title}
          summary={post.summary}
          createdAt={new Date(post.createdAt)}
          author={post.author}
          imageUrl={post.imageUrl}
        />
      </div>
    )) : (
      <div className="flex flex-col items-center justify-center py-16">
        <FontAwesomeIcon icon={faSmileBeam} size="3x" />
        <p>No matching posts found. Try a different search!</p>
      </div>
    );
  } else if (status === 'failed') {
    content = (
      <div className="flex flex-col items-center justify-center py-16">
        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
        <p>Error loading posts. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className='min-h-screen bg-white'>
      <Helmet>
        <title>The Agile Blog</title>
        <meta name="description" content="Explore expert tips and strategies that empower both students and educators." />
        <meta name="keywords" content="blog, education, tips, strategies, tech, Technology, EdTech" />
      </Helmet>
      <div className="mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-medium text-center mb-4">The Agile Blog</h1>
        <p className="text-lg text-center text-textGray mb-12">The latest and greatest news on education with a knock in technology</p>
        
        <div className="flex flex-col lg:flex-row lg:space-x-36">
          <div className="lg:w-lg:w-2/3 xl:w-3/5 animate-slideInLeft">
            {!seeAll && mostRecentPost && (
              <div className="mb-8" onClick={() => handleBlogClick(mostRecentPost)}>
                <BlogPreview 
                  key={mostRecentPost.id}
                  id={mostRecentPost.id}
                  title={mostRecentPost.title}
                  summary={mostRecentPost.summary}
                  imageUrl={mostRecentPost.imageUrl}
                  tags={mostRecentPost.tags}
                  createdAt={new Date(mostRecentPost.createdAt)}
                  author={mostRecentPost.author}
                  large
                />
              </div>
            )}
            <div className="space-y-8">
              {content}
            </div>
            {!seeAll && remainingPosts.length > 0 && (
              <button 
                className="mt-8 py-1 px-4 bg-primary text-white text-base rounded-lg shadow-md hover:bg-hoverColor transition-colors"
                onClick={handleSeeAllClick}
              >
                See All
              </button>
            )}
          </div>
          
          <div className="lg:w-1/3 mt-12 lg:mt-0">
            <h2 className="text-2xl font-bold mb-8 text-gray-800">Featured Posts</h2>
            <div className="h-[1px] bg-gray-300 mb-8"></div>
            <div className="space-y-12 animate-blurIn">
              {featuredPosts.map(post => (
                <div onClick={() => handleBlogClick(post)} key={post.id} className="cursor-pointer">
                  <FeaturedPost 
                    id={post.id}
                    title={post.title}
                    tags={post.tags}
                    author={post.author}
                    createdAt={new Date(post.createdAt)}
                  />
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
