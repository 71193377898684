import React from 'react';
import { Link } from 'react-router-dom';

/**
 * A React component that renders a featured blog post.
 *
 * @param {object} props - The component props.
 * @param {string} props.id - The ID of the blog post.
 * @param {string} props.title - The title of the blog post.
 * @param {array} props.tags - The tags associated with the blog post.
 * @param {string} props.author - The author of the blog post.
 * @param {string} props.createdAt - The creation date of the blog post.
 * @return {JSX.Element} The JSX element representing the featured blog post.
 */
const FeaturedPost = ({ id, title, tags, author, createdAt }) => {
  const formattedDateTime = createdAt
    ? new Date(createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'Date unknown';

  return (
    <Link 
      to={`/blogs/${id}`} 
      className="block mb-6 text-gray-800 hover:text-hoverColor transition-colors"
    >
      <div className="space-y-1">
        <div className="text-sm text-textGray">
          By Albuddy | {formattedDateTime}
        </div>
        <h3 className="text-lg font-medium leading-snug">{title}</h3>
      </div>
    </Link>
  );
};

export default FeaturedPost;
