import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail} from 'firebase/auth';
import { auth } from './firebaseConfig';
import { collection, query, where, getDocs  } from 'firebase/firestore';
import { db } from '../api/firebaseConfig';

const authService = {
  /**
   * Authenticates a user with their email and password.
   *
   * @param {string} email - The user's email address.
   * @param {string} password - The user's password.
   * @return {object} The result of the authentication, including the user's credentials.
   */
  loginWithEmail: async (email, password) => {
    try {
      console.log('Attempting to log in with email:', email); 
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful:', result); 
      return result;
    } catch (error) {
      console.error('Error logging in:', error); 
      throw error;
    }
  },
  /**
   * Signs up a user with the provided email and password.
   *
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @return {Promise<object>} A promise that resolves to the result of the sign up operation, including the user's credentials.
   * @throws {Error} If there is an error during the sign up process.
   */
  signUpWithEmail: async (email, password) => {
    try {
      console.log('Attempting to sign up with email:', email); 
      const result = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Sign up successful:', result); 
      return result;
    } catch (error) {
      console.error('Error signing up:', error); 
      throw error;
    }
  },
  logout: () => signOut(auth),
  onAuthStateChanged: (callback) => onAuthStateChanged(auth, callback),
  /**
   * Resets a user's password by sending a password reset email to the provided email address.
   *
   * @param {string} email - The email address of the user who needs to reset their password.
   * @return {Promise<void>} A promise that resolves when the password reset email has been sent.
   * @throws {Error} If there is an error sending the password reset email, or if the user's email address is not found.
   */
  resetPassword: async (email) => {
    try {
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No user document found for email:", email);
        throw new Error('auth/user-not-found');
      }
  
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent:', email);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      // Extract error code from Firebase error
      let errorCode;
      if (error.code) {
        errorCode = error.code; // Firebase error
      } else {
        errorCode = error.message; // Manually thrown error
      }
  
      throw new Error(errorCode);
    }
  }
  
};

export default authService;
