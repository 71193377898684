import { useEffect, useState } from 'react';

/**
 * Converts a time string in 12-hour format to 24-hour format.
 *
 * @param {string} time - The time string to convert, in the format 'HH:MM am/pm'.
 * @return {string} The converted time string in 24-hour format, in the format 'HH:MM'.
 */
const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(/(am|pm)/i);
    let [hours, minutes] = timePart.split(':');
    if (modifier.toLowerCase() === 'pm' && hours !== '12') {
        hours = parseInt(hours, 10) + 12;
    } else if (modifier.toLowerCase() === 'am' && hours === '12') {
        hours = '00';
    }
    return `${hours}:${minutes || '00'}`;
};

/**
 * Parses a time slot string and returns the start and end times in 24-hour format.
 *
 * @param {string} timeSlot - The time slot string to parse, in the format 'start - end'.
 * @return {Object} An object containing the start and end times in 24-hour format.
 */
const parseTimeSlot = (timeSlot) => {
    if (!timeSlot) return { startTime: null, endTime: null };
    const [start, end] = timeSlot.split(' - ');
    const startTime = convertTo24HourFormat(start.trim());
    const endTime = convertTo24HourFormat(end.trim());
    return {
        startTime,
        endTime
    };
};

/**
 * Creates a Date object from the given date string and time string, 
 * using the current year.
 *
 * @param {string} dateString - The date string to parse.
 * @param {string} timeString - The time string to parse.
 * @return {Date} The created Date object.
 */
const parseDate = (dateString, timeString) => {
    const now = new Date();
    const date = new Date(`${dateString} ${now.getFullYear()} ${timeString}`);
    return date;
};

/**
 * Parses the given courses and updates the parsed courses state and triggers the onParsed callback.
 *
 * @param {Object[]} courses - An array of course objects containing start and end dates and time slots.
 * @param {Function} onParsed - A callback function that receives the parsed courses.
 * @return {JSX.Element|null} Returns null as this component does not render anything.
 */
const CourseParser = ({ courses, onParsed }) => {
    const [parsedCourses, setParsedCourses] = useState([]);
    console.log(parsedCourses);
    useEffect(() => {
        if (courses.length > 0) {
            const parsed = courses.map(course => {
                const { startDate, endDate, timeSlot } = course;
                const { startTime, endTime } = parseTimeSlot(timeSlot);
                return {
                    ...course,
                    parsedStartDate: parseDate(startDate, startTime),
                    parsedEndDate: parseDate(endDate, endTime)
                };
            });
            setParsedCourses(parsed);
            onParsed(parsed);
        }
    }, [courses, onParsed]);

    return null; // This component does not render anything
};

export default CourseParser;
