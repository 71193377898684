import React, { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { RefundButton } from './Utility';

/**
 * A React functional component that displays a user's order history.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.orders - An object containing the user's orders, where each key is an order ID and each value is an order object.
 * @param {Object} props.user - The user object.
 * @return {JSX.Element} The JSX element representing the order history.
 */
export const OrderHistory = ({ orders, user }) => {
    function transformTo16DigitString(inputString) {
        // Step 1: Convert each character to its ASCII value and concatenate
        let asciiString = inputString
            .split('')
            .map(char => char.charCodeAt(0))
            .join('');
        
        // Step 2: If the concatenated string is longer than 16 characters, truncate it
        if (asciiString.length > 16) {
            asciiString = asciiString.substring(0, 16);
        }
        
        // Step 3: If the string is shorter than 16 characters, pad it with additional numbers (e.g., 0s)
        while (asciiString.length < 16) {
            asciiString += '0'; // You can choose any padding method here
        }
    
        return asciiString;
    }
    const [courses, setCourses] = useState({});

    useEffect(() => {
        /**
         * Fetches and processes course data from the orders object.
         *
         * @return {Promise<void>} Resolves when the course data has been fetched and processed.
         */
        const fetchCourses = async () => {
            const coursePromises = Object.values(orders).map(async (order) => {
                const courseDetailsPromises = order.courses.map(async (course) => {
                    const courseDoc = await getDoc(doc(db, 'courses', course.courseId));
                    return { courseId: course.courseId, title: courseDoc.exists() ? courseDoc.data().title : 'Unknown Course' };
                });
                const coursesDetails = await Promise.all(courseDetailsPromises);
                return coursesDetails.reduce((acc, curr) => {
                    acc[curr.courseId] = curr.title;
                    return acc;
                }, {});
            });

            const allCourses = await Promise.all(coursePromises);
            setCourses(allCourses.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
        };

        fetchCourses();
    }, [orders]);

    /**
     * Formats a Unix timestamp into a human-readable date string.
     *
     * @param {number} timestamp - A Unix timestamp in seconds.
     * @return {string} A date string in the format 'Month Day, Year'.
     */
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    return (
        <div className="order-history-container w-full mx-auto py-6 bg-white rounded-lg">
            {/* Desktop Table View */}
            <table className="hidden md:table min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-4 px-4 border-b border-gray-200 text-left text-text text-lg font-semibold">Course Name</th>
                        <th className="py-4 px-4 border-b border-gray-200 text-left text-text text-lg font-semibold">Date</th>
                        <th className="py-4 px-4 border-b border-gray-200 text-left text-text text-lg font-semibold">Amount</th>
                        <th className="py-4 px-4 border-b border-gray-200 text-left text-text text-lg font-semibold">Order ID</th>
                        <th className="py-4 px-6 border-b border-gray-200 text-right text-text text-lg font-semibold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(orders).map((orderId) => {
                        const order = orders[orderId];
                        return (
                            <tr key={orderId} className="hover:bg-gray-100">
                                <td className="py-4 px-4 border-b border-gray-200 text-left text-gray-800">{courses[order.courses[0]?.courseId] || 'Unknown Course'}</td>
                                <td className="py-4 px-4 border-b border-gray-200 text-left text-gray-800">{formatDate(order.created)}</td>
                                <td className="py-4 px-4 border-b border-gray-200 text-left text-gray-800">${order.amount / 100}</td>
                                <td className="py-4 px-4 border-b border-gray-200 text-left text-gray-800">#{transformTo16DigitString(orderId)}</td>
                                <td className="py-4 px-4 border-b border-gray-200 text-right">
                                    <RefundButton user={user} order={order} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Mobile Card View */}
            <div className="md:hidden space-y-4">
                {Object.keys(orders).map((orderId) => {
                    const order = orders[orderId];
                    return (
                        <div key={orderId} className="bg-white p-4 rounded-lg shadow-lg">
                            <div className="mb-2">
                                <h4 className="text-gray-800 font-semibold">Course Name</h4>
                                <p className="text-gray-600">{courses[order.courses[0]?.courseId] || 'Unknown Course'}</p>
                            </div>
                            <div className="mb-2">
                                <h4 className="text-gray-800 font-semibold">Date</h4>
                                <p className="text-gray-600">{formatDate(order.created)}</p>
                            </div>
                            <div className="mb-2">
                                <h4 className="text-gray-800 font-semibold">Amount</h4>
                                <p className="text-gray-600">${order.amount / 100}</p>
                            </div>
                            <div className="mb-2">
                                <h4 className="text-gray-800 font-semibold">Order ID</h4>
                                <p className="text-gray-600">#{orderId}</p>
                            </div>
                            <div className="text-right mt-8">
                                <RefundButton user={user} order={order}  />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
