import { useState, useEffect } from 'react';

/**
 * A custom React hook that tracks the current window size.
 *
 * @return {Object} An object containing the current window width and height.
 */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    /**
     * Handles the resize event by updating the window size state.
     *
     * @return {void} No return value.
     */
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener('resize', handleResize);

    handleResize(); // Set the size initially
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;