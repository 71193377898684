// src/pages/NotFound/index.js
import React from 'react';
import './NotFound.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/srcSetImages/logov2/logo-small.webp';

/**
 * A React functional component that renders a 404 Not Found page.
 *
 * @return {JSX.Element} The JSX element representing the 404 page.
 */
function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <img src={logo} alt="Logo" className="logo" />
      <FontAwesomeIcon icon={faFrown} className="not-found-icon" />
      <h1>404</h1>
      <p>Oops! The page you're looking for isn't here.</p>
      <p>You might have the wrong address, or the page may have moved.</p>
      <button onClick={() => navigate('/')} className="home-button">
        <FontAwesomeIcon icon={faHome} />
        Take me home
      </button>
    </div>
  );
}

export default React.memo(NotFound);
