import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses } from '../../redux/slices/courseSlice';
import CoursePreview from '../../components/CoursePreview/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../components/SearchBar/index';
import Footer from '../../components/Footer';
import Fuse from 'fuse.js';
import gif from '../../assets/img/newheroCourse.png';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

/**
 * A React functional component that renders the Course Page.
 * It handles course data fetching, filtering, and searching.
 * It also renders the course grid, search bar, and slogan section.
 *
 * @return {JSX.Element} The JSX element representing the Course Page
 */
function CoursePage() {
  const dispatch = useDispatch();
  const { courses, status, error } = useSelector((state) => state.courses);
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  console.log(isMobile);
  useEffect(() => {
    dispatch(fetchCourses());
    logEvent(analytics, 'page_view', { page_title: 'Course Page' });
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      const options = {
        keys: ['title', 'description', 'instructor'],
        includeScore: true,
        threshold: 0.4,
      };
      const fuse = new Fuse(courses, options);

      let result = courses;

      // Apply search term filter
      if (searchTerm !== '') {
        result = fuse.search(searchTerm).map(({ item }) => item);
        logEvent(analytics, 'search', { search_term: searchTerm });
      }

      // Apply category filter
      if (selectedCategory) {
        result = result.filter((course) => course.category.includes(selectedCategory));
        logEvent(analytics, 'filter_category', { category: selectedCategory });
      }

      // Apply price range filter
      if (selectedPriceRange) {
        const [minPrice, maxPrice] = selectedPriceRange.split('-').map(Number);
        result = result.filter((course) => course.price >= minPrice && course.price <= maxPrice);
        logEvent(analytics, 'filter_price', { price_range: selectedPriceRange });
      }

      setSearchResult(result);
    }
  }, [courses, searchTerm, selectedCategory, selectedPriceRange, status]);

  useEffect(() => {
    /**
     * Handles window resize event to determine if the screen is mobile-sized.
     *
     * @return {void}
     */
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Renders a grid of four slogan cards with a step-by-step approach to success.
   *
   * The grid is responsive and adjusts its layout based on the screen size.
   * Each card contains a number or icon, a brief description, and a hover effect.
   *
   * @return {JSX.Element} A JSX element representing the slogan grid.
   */
  const renderSloganGrid = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-12">
        <div className="flex flex-col sm:flex-row items-center justify-center bg-white rounded-lg p-4 shadow-lg transition-transform transform hover:-translate-y-2">
          <span className="text-5xl font-bold text-blue-600 mb-2 sm:mb-0 sm:mr-4 text-center">1</span>
          <span className="text-lg text-gray-800">Pick Your Path</span><br/>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center bg-white rounded-lg p-4 shadow-lg transition-transform transform hover:-translate-y-2">
          <span className="text-5xl font-bold text-blue-600 mb-2 sm:mb-0 sm:mr-4 text-center">2</span>
          <span className="text-lg text-gray-800 text-center">Customize Your Project</span>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center bg-white rounded-lg p-4 shadow-lg transition-transform transform hover:-translate-y-2">
          <span className="text-5xl font-bold text-blue-600 mb-2 sm:mb-0 sm:mr-4 text-center">3</span>
          <span className="text-lg text-gray-800 text-center">Build Real-World Experience</span>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center bg-white rounded-lg p-4 shadow-lg transition-transform transform hover:-translate-y-2">
          <FontAwesomeIcon icon={faCheckCircle} className="text-blue-600 text-2xl mb-2 sm:mb-0 sm:mr-4 text-center" />
          <span className="text-lg text-gray-800 text-center">Only three steps to success</span>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-white py-10">
          <header className="flex flex-col md:flex-row items-center justify-center mb-10 px-4 md:px-10 text-center md:text-left max-w-7xl mx-auto">
            <img src={gif} alt="description" className="w-full md:w-1/2 h-auto max-w-md mb-8 md:mb-0 md:mr-10" />
            <div>
              <h1 className="text-4xl md:text-5xl font-medium text-webDarker">Explore Our Courses</h1>
              <section className="mt-4">
                <h2 className="text-2xl md:text-3xl text-webDarker mb-4">Get Hands-On Experience That Sets You Apart</h2>
                <p className="text-lg text-textGray">
                Feeling anxious about lacking the skills that top schools and companies demand? Our project-based courses in software development, robotics, and more give you the real-world experience you need to stand out. Each project is designed to build your portfolio and prove your expertise.
                </p>
                
              </section>
            </div>
          </header>
        </div>

        {/* Main Content Wrapper */}
        <div className="px-4 md:px-10 max-w-7xl mx-auto">
          {/* Slogan Section */}
          {renderSloganGrid()}

          {/* Search and Select Section */}
          <div className="flex flex-col lg:flex-row justify-between items-center mb-10">
            <div className="flex gap-4 w-full lg:w-auto">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg text-gray-700 w-full lg:w-auto"
              >
                <option value="">All Categories</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Innovation">Innovation</option>
                <option value="Startup">Startup</option>
                <option value="AI Tools">AI Tools</option>
                <option value="The Future">The Future</option>
                <option value="AI">AI</option>
                <option value="Job Ready">Job Ready</option>
                <option value="Projects">Projects</option>
                <option value="Website">Website</option>
              </select>
              <select
                value={selectedPriceRange}
                onChange={(e) => setSelectedPriceRange(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg text-gray-700 w-full lg:w-auto"
              >
                <option value="">All Prices</option>
                <option value="100-400">$100 - 400</option>
                <option value="400-1000">$400 - 1000</option>
                <option value="1000-2000">$1000 - 2000</option>
              </select>
            </div>
            <div className="w-full lg:w-auto mt-4 lg:mt-0">
              <SearchBar onSearchChange={setSearchTerm} placeholder="Search Courses..." delay={500} className="w-full lg:w-auto" />
            </div>
          </div>

          {/* Course Grid Section */}
          <div className="text-center mb-6">
            <p className="text-lg text-gray-800">Showing {searchResult.length} results</p>
          </div>
          {status === 'loading' ? (
            <div className="flex justify-center items-center py-10">
              <FontAwesomeIcon icon={faSpinner} className="text-4xl text-webDarker animate-spin" />
            </div>
          ) : error ? (
            <p className="text-red-500 text-center">Error: {error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
              {searchResult.map((course) => (
                <div key={course.id} className="bg-white rounded-lg p-4 animate-blurIn">
                  <CoursePreview course={course} />
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CoursePage;
