import React from 'react';
import marcus from '../../assets/img/marCusBIg.jpg';

/**
 * A React component that renders the About Us section of the application.
 * 
 * This component includes a brief introduction to Albuddy, its mission, and a personal message from the founder.
 * 
 * @return {JSX.Element} The About Us section of the application.
 */
function HistorySection() {
  return (
    <section id='history' className="py-16 px-4 lg:px-20 bg-white">
      <div className="text-center mb-16">
        <h2 className="text-5xl font-medium">About Us</h2>
        <p className="text-textGray mt-4 max-w-2xl mx-auto">
          Albuddy is committed to demystifying computer science. By fostering hands-on experience, 
          we aim to equip learners with the knowledge to excel in technical interviews and the competence 
          to independently deploy functional applications.
        </p>
      </div>
      <div className="flex flex-col mx-4 lg:flex-row items-center lg:justify-between lg:gap-12">
        {/* Image Section */}
        <div className="relative w-full max-w-sm mx-auto lg:w-1/2 mb-8 lg:mb-0">
          {/* Background Layer 1 - Bottom Right */}
          <div className="absolute opacity-80 top-0 right-0 w-full h-full transform translate-x-4 translate-y-4 lg:translate-x-6 lg:translate-y-6 bg-gradient-to-r from-[#3B76D6] via-[#60afe3] to-[#b7e7f7] rounded-lg shadow-3d z-0"></div>
          <img
            src={marcus}
            alt="Marcus Profile"
            className="relative shadow-3d z-20 w-full h-auto rounded-lg"
          />
        </div>

        {/* Text Section */}
        <div className="flex flex-col lg:w-1/2 text-left">
          <h3 className="text-3xl lg:text-5xl font-medium text-center text-text mb-4 mt-8 lg:mt-0">Hey there! I'm Marcus, </h3>
          <h3 className="text-3xl lg:text-5xl font-medium text-center text-text mb-4">founder of Albuddy.</h3>
          <div className="flex flex-col gap-8 lg:flex-row lg:gap-12 mt-8">
            <p className="text-center md:text-left text-textGray">
              Albuddy was born from a collaboration with my colleague Jack. We noticed many soon-to-be graduates 
              lacked practical programming skills. Using my experience as a full-stack developer, we founded Albuddy 
              to merge real-world coding with the agile development process, enhancing learning through actual practice.
            </p>
            <p className="text-center md:text-left text-textGray">
              My love for creating new things kicked off my coding journey—starting without any guide. 
              I learned by diving straight into projects, figuring out the pieces as I went along. 
              This hands-on approach not only built my skills but also taught me how to weave together development 
              and learning.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HistorySection;