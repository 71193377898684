import React, { useState, useEffect, useRef } from 'react';

/**
 * A functional component that renders a tabbed interface.
 *
 * @param {object} tabs - An array of tab objects with 'name' and 'value' properties.
 * @param {function} handleTabClick - An optional callback function that is called when a tab is clicked.
 * @return {JSX.Element} The rendered tabbed interface.
 */
const Tabs = ({ tabs, handleTabClick }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabHeight, setTabHeight] = useState('auto');
  const tabContainerRef = useRef(null);

  /**
   * Handles the change of the active tab.
   *
   * @param {number} index - The index of the newly active tab.
   * @return {void}
   */
  const handleTabChange = (index) => {
    setActiveTab(index);
    if (handleTabClick && typeof handleTabClick === 'function') {
      handleTabClick(tabs[index].value);
    }
  };

  useEffect(() => {
    if (tabContainerRef.current) {
      const tabButtons = tabContainerRef.current.querySelectorAll('button');
      let maxHeight = 0;
      tabButtons.forEach((button) => {
        const height = button.scrollHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      const adjustedHeight = Math.max(maxHeight, 44);
      setTabHeight(`${adjustedHeight}px`);
    }
  }, [tabs]);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="w-full sm:w-7/12 mx-auto">
        <div
          role="tablist"
          aria-label="tabs"
          className="relative w-full h-auto sm:h-12 grid grid-cols-2 items-center px-1 sm:px-1.5 rounded-full bg-gray-200 overflow-hidden shadow-md"
          style={{
            gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
            height: tabHeight,
          }}
          ref={tabContainerRef}
        >
          <div
            className="absolute indicator top-0 bottom-0 left-0 rounded-full bg-white shadow transition-transform duration-300 ease-in-out"
            style={{
              width: `${100 / tabs.length}%`,
              transform: `translateX(${activeTab * 100}%)`,
              height: tabHeight,
            }}
          ></div>

          {tabs.map((tab, index) => (
            <button
              key={index}
              role="tab"
              aria-selected={activeTab === index}
              aria-controls={`panel-${index}`}
              id={`tab-${index}`}
              tabIndex={activeTab === index ? 0 : -1}
              onClick={() => handleTabChange(index)}
              className={`relative block h-full px-2 sm:px-4 text-base sm:text-sm rounded-full transition-colors duration-300 ${
                activeTab === index ? 'text-webDarker' : 'text-gray-800'
              }`}
              style={{ lineHeight: '1.5' }}
            >
              <span>{tab.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
/* Render the active tab content */
      /* <div className="mt-6 relative rounded-3xl bg-purple-50">
          {tabs.map((tab, index) => (
            <div
              key={index}
              role="tabpanel"
              id={`panel-${index}`}
              className={`tab-panel p-6 transition-opacity duration-300 ${
                activeTab === index ? 'opacity-100 relative' : 'opacity-0 invisible absolute'
              }`}
            >
              {tab.content}
            </div>
          ))}
        </div> */