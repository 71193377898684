import {collection, addDoc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';

/**
 * A React functional component that renders a refund request button.
 *
 * @param {object} order - The order object associated with the refund request.
 * @param {object} user - The user object associated with the refund request.
 * @return {JSX.Element} A JSX element representing the refund request button.
 */
export const RefundButton = ({ order, user }) => {
    /**
     * Handles the refund request by creating a refund request object with the user's name, email, and order,
     * and adds it to the "refundRequests" collection in the Firestore database.
     * Displays an alert message indicating that the refund request was submitted successfully,
     * and logs the ID of the newly created document to the console.
     * If there is an error adding the document, logs the error to the console.
     *
     * @return {Promise<void>} - A promise that resolves when the refund request is successfully submitted.
     */
    const handleRefundRequest = async () => {
        try {
            const refundRequest = {
                userName: user.name,
                userEmail: user.email,
                orderRequested: order,
            };
            const docRef = await addDoc(collection(db, "refundRequests"), refundRequest);
            alert("Refund request submitted successfully!");
            console.log('Document written with ID: ', docRef.id);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <button
            className="bg-accent text-white py-2 px-4 rounded-full transition duration-300 ease-in-out hover:bg-hoverColor focus:outline-none"
            onClick={handleRefundRequest}
        >
            Request Refund
        </button>
    );
};


/**
 * Returns a filtered array of past events from the given courses.
 *
 * @param {Object[]} courses - An array of course objects with parsedStartDate and parsedEndDate properties.
 * @return {Object[]} An array of course objects that have ended.
 */
export const getPastEvents = (courses) => {
    const now = new Date();
    return courses.filter(course => {
        const { parsedStartDate, parsedEndDate } = course;
        console.log(parsedStartDate)
        return parsedEndDate < now;
    });
};

/**
 * Returns a filtered array of ongoing events from the given courses.
 *
 * @param {Object[]} courses - An array of course objects with parsedStartDate and parsedEndDate properties.
 * @return {Object[]} An array of course objects that are currently ongoing.
 */
export const getOngoingEvents = (courses) => {
    const now = new Date();
    return courses.filter(course => {
        const { parsedStartDate, parsedEndDate } = course;
        return parsedStartDate <= now && parsedEndDate >= now;
    });
};

/**
 * Returns a filtered array of upcoming events from the given courses.
 *
 * @param {Object[]} courses - An array of course objects with parsedStartDate and parsedEndDate properties.
 * @return {Object[]} An array of course objects that are scheduled to start within the next three days.
 */
export const getUpcomingEvents = (courses) => {
    const now = new Date();
    const threeDaysFromNow = new Date();
    threeDaysFromNow.setDate(now.getDate() + 3);
    return courses.filter(course => {
        const { parsedStartDate, parsedEndDate } = course;
        console.log(parsedEndDate)
        return parsedStartDate > now && parsedStartDate <= threeDaysFromNow;
    });
};

/**
 * Returns a filtered array of future events from the given courses.
 *
 * @param {Object[]} courses - An array of course objects with parsedStartDate property.
 * @return {Object[]} An array of course objects that are scheduled to start more than three days from now.
 */
export const getFutureEvents = (courses) => {
    const now = new Date();
    const threeDaysFromNow = new Date();
    threeDaysFromNow.setDate(now.getDate() + 3);
    return courses.filter(course => {
        const { parsedStartDate } = course;
        return parsedStartDate > threeDaysFromNow;
    });
};

