import { combineReducers } from 'redux';
import userReducer from './slices/userSlice';
import blogReducer from './slices/blogSlice';
import courseReducer from './slices/courseSlice';
import cartReducer from './slices/cartSlice';
import applicationReducer  from './slices/ApplicationSlice';
import customerResponsesReducer from './slices/customerResponsesSlice';


const rootReducer = combineReducers({
  user: userReducer,
  blog: blogReducer,
  courses: courseReducer,
  cart: cartReducer,
  application: applicationReducer,
  customerResponses: customerResponsesReducer,
});

export default rootReducer;
