// src/components/ServicesSection/ServicesSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faNetworkWired, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

/**
 * A React component that renders a section of services, including programming fundamentals, software engineering principles, and project-based learning.
 *
 * @return {JSX.Element} A JSX element representing the services section.
 */
function ServicesSection() {
  return (
    <section className="services-section flex flex-col md:flex-row justify-around p-16 bg-white text-center">
      <div className="service flex-grow m-4 p-4 transition-transform duration-300 ease-in-out hover:translate-y-[-5px]">
        <FontAwesomeIcon icon={faLaptopCode} size="3x" className="service-icon mb-4 text-webDarker transition-transform duration-300 ease-in-out hover:scale-110" />
        <h2 className="font-bold text-lg mb-2">Programming Mastery</h2>
        <p className="text-gray-500">Feeling overwhelmed by too many coding languages? At Albuddy, we simplify learning. Master essential programming skills across multiple languages so you’re ready for any challenge, whether it’s for top schools or leading tech companies.</p>
      </div>
      <div className="service flex-grow m-4 p-4 transition-transform duration-300 ease-in-out hover:translate-y-[-5px]">
        <FontAwesomeIcon icon={faNetworkWired} size="3x" className="service-icon mb-4 text-webDarker transition-transform duration-300 ease-in-out hover:scale-110" />
        <h2 className="font-bold text-lg mb-2">Real World System Design</h2>
        <p className="text-gray-500">Worried theory won’t help you stand out? Albuddy turns theory into action. Learn to design real-world systems and gain the skills top schools and companies look for.</p>
      </div>
      <div className="service flex-grow m-4 p-4 transition-transform duration-300 ease-in-out hover:translate-y-[-5px]">
        <FontAwesomeIcon icon={faProjectDiagram} size="3x" className="service-icon mb-4 text-webDarker transition-transform duration-300 ease-in-out hover:scale-110" />
        <h2 className="font-bold text-lg mb-2">Portfolio-driven Learning</h2>
        <p id="whyUs" className="text-gray-500">Not sure how to show your skills? Build a portfolio with real-world projects that prove your abilities to schools and employers—and give you the confidence to succeed.</p>
      </div>
    </section>
  );
}

export default ServicesSection;
