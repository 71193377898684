import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../api/firebaseConfig';
import { addDoc, collection } from 'firebase/firestore';

export const addCustomerResponse = createAsyncThunk(
  'customerResponses/addCustomerResponse',
  async ({ email, phoneNumber, question }, { rejectWithValue }) => {
    try {
      await addDoc(collection(db, 'customerResponses'), {
        email,
        phoneNumber,
        question,
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addCustomerResponseWithNames = createAsyncThunk(
  'customerResponses/addCustomerResponseWithNames',
  async ({ firstName, lastName, email, message }, { rejectWithValue }) => {
    try {
      await addDoc(collection(db, 'customerResponses'), {
        firstName,
        lastName,
        email,
        message,
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const customerResponsesSlice = createSlice({
  name: 'customerResponses',
  initialState: {
    status: 'idle',
    error: null,
  },
  /**
   * Configures extra reducers for handling customer response actions.
   *
   * @param {object} builder - The builder object for configuring extra reducers.
   * @return {void}
   */
  extraReducers: (builder) => {
    builder
      .addCase(addCustomerResponse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCustomerResponse.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(addCustomerResponse.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handle new case with names
      .addCase(addCustomerResponseWithNames.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCustomerResponseWithNames.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(addCustomerResponseWithNames.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default customerResponsesSlice.reducer;