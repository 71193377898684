// work in progress!!!!
import React from 'react';
import Footer from '../../components/Footer';


function SignupOneOnOne() {
  return (
    <div className='app-container'>
      <div className='content'>
        <h2>One-on-One Signup</h2>
        
      </div>
      <Footer />
    </div>
  );
}

export default SignupOneOnOne;
