import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleLeft, faAngleRight, faBan } from '@fortawesome/free-solid-svg-icons';
import CourseCard from './CourseCard';
import useWindowSize from './useWindowSize';
/**
 * Renders a list of upcoming classes.
 *
 * @param {Object[]} upcomingCourses - An array of objects containing course information.
 * @param {function} handleCourseClick - A callback function to handle course clicks.
 * @return {JSX.Element} A JSX element representing the list of upcoming classes.
 */
export const RendarNextClass = ({ upcomingCourses, handleCourseClick }) => {
    return (
        <div className="mt-6">
            <h3 className="text-lg font-semibold mb-12 mt-12">Your Active Classes:</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                {upcomingCourses.length > 0 ? (
                    upcomingCourses.map((course, index) => (
                        <CourseCard 
                            key={index}
                            title={course.title}
                            instructor={course.instructor}
                            startDate={course.startDate}
                            endDate={course.endDate}
                            onClick={() => handleCourseClick(course)}
                        />
                    ))
                ) : (
                    <p className="text-gray-400 text-left">
                        <FontAwesomeIcon icon={faBan} /> No classes found.
                    </p>
                )}
            </div>
        </div>
    );
};

export const RenderCalendar = ({
    events,
    selectedYear,
    selectedMonth,
    getEventMarkerColor,
    handleCellClick,
    setSelectedYear,
    setSelectedMonth,
    handleIconClick,
    colorPalette,
}) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const firstDay = new Date(selectedYear, selectedMonth, 1).getDay();
    const calendarCells = [];

    const renderEventAbbreviations = (eventsForDay, isMobile) => {
        const colorMapper = {};
        let colorIndex = 0;
        return eventsForDay.map((event, index) => {
            const { courseId } = event;
            if (!colorMapper[courseId]) {
                colorMapper[courseId] = colorPalette[colorIndex % colorPalette.length];
                colorIndex++;
            }
    
            // Get the assigned color for the current courseId
            const assignedColor = colorMapper[courseId];
            const abbreviation = event.title
                .split(' ')
                .map(word => word.charAt(0).toUpperCase())
                .join('');
            return (
                <div
                    key={index}
                    //className={`${assignedColor} text-white text-xs font-semibold px-2 py-1 rounded mb-1 text-center cursor-pointer`}
                    className={`${assignedColor} ${isMobile ? 'w-2 h-2 border-2 rounded-full cursor-pointer' : 'text-white text-xs font-semibold px-2 py-1 rounded mb-1 text-center'} cursor-pointer`}
                    onClick={(e) => handleIconClick(e, event)}
                >
                    {!isMobile && abbreviation}
                    
                </div>
                
            );
        });
    };

    for (let i = 0; i < firstDay; i++) {
        calendarCells.push(<td key={`empty-start-${i}`} className="border border-gray-200"></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = new Date(selectedYear, selectedMonth, day);
        const eventsForDay = events.filter(event => {
            const eventStartDate = new Date(event.parsedStartDate);
            const eventEndDate = new Date(event.parsedEndDate);
            return (currentDate >= eventStartDate && currentDate <= eventEndDate) || currentDate.toDateString() === eventStartDate.toDateString();
        });

        let cellClass = 'bg-white'; 
        eventsForDay.forEach(event => {
            const className = getEventMarkerColor(event, currentDate);
            if (className) {
                cellClass = className;
            }
        });

        const isToday = currentDate.toDateString() === new Date().toDateString();
        calendarCells.push(
            <td 
                key={day} 
                className={`h-20 w-16 md:h-32 md:w-32 lg:h-40 lg:w-40 border border-gray-200 ${isToday ? 'bg-blue-200' : ''} ${cellClass} relative`} 
                onClick={() => handleCellClick(day)}
            >
                <span className={`absolute top-1 left-1 text-xs md:text-sm lg:text-lg font-normal text-gray-800 ${isToday?'bg-indigo-500 rounded-full px-1 text-white':''}`}>{day}</span>
                <div className="absolute bottom-1 left-1 space-y-1 md:space-y-2 lg:space-y-3">
                    {renderEventAbbreviations(eventsForDay, isMobile)}
                </div>
                {/* {isToday && <span className="absolute bottom-1 right-1 block w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-black rounded-full"></span>} */}
            </td>
        );
        
        
        
        
    }

    while (calendarCells.length % 7 !== 0) {
        calendarCells.push(<td key={`empty-end-${calendarCells.length}`} className="border border-transparent"></td>);
    }

    const weeks = [];
    for (let i = 0; i < calendarCells.length; i += 7) {
        weeks.push(<tr key={`week-${i / 7}`}>{calendarCells.slice(i, i + 7)}</tr>);
    }

    return (
        <div className="p-4 bg-white rounded-lg">
            {isMobile ? (
                // Mobile Design
                <div className="flex items-center justify-between mb-4">
                    <button 
                        onClick={() => setSelectedMonth(selectedMonth - 1)} 
                        disabled={selectedMonth === 0} 
                        className="flex items-center justify-center w-10 h-10 text-textGray bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="text-center">
                        <span className="text-lg font-medium text-text">{new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' })}</span>
                        <br />
                        <span className="text-sm text-textGray">{selectedYear}</span>
                    </div>
                    <button 
                        onClick={() => setSelectedMonth(selectedMonth + 1)} 
                        disabled={selectedMonth === 11} 
                        className="flex items-center justify-center w-10 h-10 text-textGray bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            ) : (
                // Desktop Design
                <div className="flex items-center justify-items-start space-x-8 mb-4">
                    <div className="flex items-center justify-center">
                        <button 
                            onClick={() => setSelectedYear(selectedYear - 1)} 
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <span className="mx-2 text-3xl px-4 font-medium text-text">{selectedYear}</span>
                        <button 
                            onClick={() => setSelectedYear(selectedYear + 1)} 
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="flex items-center">
                        <button 
                            onClick={() => setSelectedMonth(selectedMonth - 1)} 
                            disabled={selectedMonth === 0} 
                            className="text-textGray hover:text-gray-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <span className="mx-2 text-3xl px-4 font-medium text-text">{new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'long' })}</span>
                        <button 
                            onClick={() => setSelectedMonth(selectedMonth + 1)} 
                            disabled={selectedMonth === 11} 
                            className="text-textGray hover:text-gray-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>
                </div>
            )}
            <table className="w-full table-fixed border-collapse">
                <thead>
                    <tr>
                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                            <th key={day} className="w-1/7 text-base md:text-sm text-center text-gray-600">{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {weeks}
                </tbody>
            </table>
        </div>
    );
};

