import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCourse } from '../../redux/slices/courseSlice';
import { addItemToCart } from '../../redux/slices/cartSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//temporary enrollment icon
//import { faCalendarAlt, faUserGraduate, faTag, faSpinner, faExclamationTriangle, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
//original enrollment icon
import { faCalendarAlt, faUserGraduate, faTag, faSpinner, faExclamationTriangle, faAngleLeft,faTimesCircle, faCheckCircle, faTimes, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer';
import CartModal from '../CartModal';
import { db, analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { doc, onSnapshot } from 'firebase/firestore';

import { isAfter } from 'date-fns';
import TemporarySignup from './TemporarySignup';

import enpImage from '../../assets/img/enp2.png';
import webDevImage from '../../assets/img/WebDev2.png';
import webDevAiImage from '../../assets/img/WebDevAi2.png';
import peImage from '../../assets/img/promptEngineering2.png';
import pythonImage from '../../assets/img/python101.png';
import javaImage from '../../assets/img/Java101.png';
import javascriptImage from '../../assets/img/js101.png';
import HTMLcssImage from '../../assets/img/htmlandcss.png';
/*
TODO: to turn back the actual enrollment back on, follow the below steps:
1. uncomment the import of faicons and remove the temporary one
*/

/**
 * Returns the image based on the provided image URL.
 *
 * @param {string} imageUrl - the URL of the image to retrieve
 * @return {object} the image object corresponding to the provided URL
 */
const getImage = (imageUrl) => {
    if (imageUrl.includes('enpImage')) {
        return enpImage;
    } else if (imageUrl.includes('WDEImage')) {
        return webDevImage;
    } else if (imageUrl.includes('WDEAIImage')) {
        return webDevAiImage;
    } else if (imageUrl.includes('PEImage')) {
        return peImage;
    } else if (imageUrl.includes('PythonImage')) {
        return pythonImage;
    } else if (imageUrl.includes('JavaImage')) {
        return javaImage;
    } else if (imageUrl.includes('JavaScriptImage')) {
        return javascriptImage;
    } else if (imageUrl.includes('HTMLcssImage')) {
        return HTMLcssImage;
    }
};

/**
 * Returns the price of a course based on whether it is discounted or not.
 *
 * @param {object} course - the course object containing price and discount information
 * @return {number} the price of the course
 */
const getCoursePrice = (course) => {
    return course.isDiscounted ? course.discountedPrice : course.price;
};

/**
 * A React functional component that displays the details of a course.
 * 
 * It fetches the course data from the database, handles user enrollment, 
 * and provides options to add the course to the cart.
 * 
 * @return {JSX.Element} The JSX element representing the course details page.
 */
const CourseDetails = () => {
    const dispatch = useDispatch();
    const { courseId } = useParams();
    const user = useSelector(state => state.user.user);
    const { course, status, error } = useSelector(state => state.courses);
    const [expandedSchedule, setExpandedSchedule] = useState({});
    const [expandedSessions, setExpandedSessions] = useState({});
    const [cartItem, setCartItem] = useState(null);
    const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(true);
    const [showCartModal, setShowCartModal] = useState(false);
    const [showAddedToCartPopup, setShowAddedToCartPopup] = useState(false);
    const [latestUser, setLatestUser] = useState(user);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [disableEnrollment] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.uid) {
            const userDocRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    setLatestUser(docSnap.data());
                }
            });

            return () => unsubscribe();
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchSingleCourse(courseId));
        logEvent(analytics, 'page_view', { page_title: 'Course Details Page', course_id: courseId });
    }, [courseId, dispatch]);

    useEffect(() => {
        if (course?.sampleSchedule) {
            const initialScheduleState = Object.keys(course.sampleSchedule).reduce((acc, day) => {
                acc[day] = false;
                return acc;
            }, {});
            setExpandedSchedule(initialScheduleState);
        }

        if (course?.sessions) {
            const initialSessionsState = course.sessions.reduce((acc, session) => {
                acc[session.id] = false;
                return acc;
            }, {});
            setExpandedSessions(initialSessionsState);
        }
    }, [course]);

    /**
     * Handles the enrollment of a course session.
     *
     * Creates a unique cart item based on the provided session and time slot,
     * updates the cart item state, enables the purchase, and logs the enroll now event.
     *
     * @param {object} session - The course session object.
     * @param {object} timeSlot - The time slot object.
     * @return {void}
     */
    const handleEnrollNow = (session, timeSlot) => {
        const uniqueSessionTimeId = `${session.id}-${timeSlot.time.replace(/\s+/g, '')}`;
        const cartItem = {
            courseId: course.id,
            courseName: course.title,
            courseImage: getImage(course.imageUrl),
            isDiscounted: course.isDiscounted,
            price: getCoursePrice(course),
            session: {
                id: session.id,
                startDate: session.startDate,
                endDate: session.endDate,
                timeSlot: timeSlot.time,
            },
            timeChosen: timeSlot.time,
            quantity: 1,
            uniqueSessionTimeId: uniqueSessionTimeId,
        };
        setCartItem(cartItem);
        setIsPurchaseDisabled(false);
        logEvent(analytics, 'enroll_now', { course_id: course.id, session_id: session.id, time_slot: timeSlot.time });
    };

    /**
     * Appends a new time slot to the selected times array if it is not already included.
     *
     * @param {object} timeSlot - The time slot object to be added.
     * @return {void}
     */
    const appendAddedSlot = (timeSlot)=>{
        if (!selectedTimes.includes(timeSlot.time)) {
            setSelectedTimes(prevTimes => [...prevTimes, timeSlot.time]);
        }
    }
    /**
     * Removes the specified time from the selected times array and updates the cart item state accordingly.
     *
     * @param {string} time - The time to be removed from the selected times array.
     * @return {void}
     */
    const handleRemoveSelectedTime = (time) => {
        if (selectedTimes.includes(time)) {
            setSelectedTimes(prevTimes => prevTimes.filter((t) => t !== time));
            if(cartItem.timeChosen ===time){
                setCartItem(null);
                setIsPurchaseDisabled(true);
            }
        }
    }

    /**
     * Handles the purchase of a cart item.
     *
     * Checks if the cart item is not null, then dispatches the action to add the item to the cart,
     * shows the added to cart popup, and logs the purchase button event.
     *
     * @return {void}
     */
    const handlePurchase = () => {
        if (cartItem == null) {
            return;
        } else {
            dispatch(addItemToCart(cartItem));
            setShowAddedToCartPopup(true);
            setTimeout(() => setShowAddedToCartPopup(false), 3000);
            setIsPurchaseDisabled(true);
            setShowCartModal(true);
            logEvent(analytics, 'purchase_button', { cartItem: cartItem });
        }
    };
    // const handleCloseAgain = () => {
    //     setShowCartModal(false);
    // };

    /**
     * Toggles the expanded state of a session item.
     *
     * @param {string} sessionId - The ID of the session to toggle.
     * @return {void}
     */
    const toggleSessionItem = (sessionId) => {
        setExpandedSessions(prevState => ({
            ...prevState,
            [sessionId]: !prevState[sessionId]
        }));
    };

    const toggleScheduleItem = (day) => {
        setExpandedSchedule(prevState => ({
            ...prevState,
            [day]: !prevState[day]
        }));
    };

    /**
     * Checks if the user is registered for a specific course session.
     *
     * @param {string} courseId - The ID of the course to check.
     * @param {string} sessionId - The ID of the session to check.
     * @return {boolean} True if the user is registered for the session, false otherwise.
     */
    const isRegisteredForSession = (courseId, sessionId) => {
        if (!latestUser || !latestUser.registeredCourses) return false;
        const registeredSessions = Object.keys(latestUser.registeredCourses);
        return registeredSessions.includes(`${courseId}_${sessionId}`);
    };

    /**
     * Converts a date string to a full date object by appending the current year.
     *
     * @param {string} dateString - The date string to be converted (e.g., 'Jan 1' or '12/31').
     * @return {Date} A Date object representing the full date.
     */
    const parseDate = (dateString) => {
        const currentYear = new Date().getFullYear();
        const fullDateString = `${dateString} ${currentYear}`;
        return new Date(fullDateString);
    };

/**
 * Checks if a session is expired based on its end date.
 *
 * @param {Object} session - The session object containing an end date.
 * @param {string} session.endDate - The end date of the session in a string format.
 * @return {boolean} True if the session is expired, false otherwise.
 */
    const isSessionExpired = (session) => {
        return isAfter(new Date(), parseDate(session.endDate));
    };

    const isCourseExpired = course?.sessions?.every(session => isSessionExpired(session));

    /**
     * Renders the course schedule based on the provided schedule data.
     * 
     * @param {object} schedule - The schedule data to be rendered.
     * @return {JSX.Element|null} The rendered schedule component, or null if the schedule data is invalid.
     */
    const renderSchedule = (schedule) => {
        if (!schedule || typeof schedule !== 'object') return null;
    
        const orderDays = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
    
        const iteratingObjects = (data, orderDays) => {
            let result = [];
            orderDays.forEach(week => {
                if (data[week] === undefined) {
                    return;
                }
                if (!data[week] || data[week][0] === null) {
                    result.push({ week, activities: ["No activities scheduled"] });
                } else {
                    let activities = [];
                    data[week].forEach(day => {
                        for (const dayName in day) {
                            activities.push({ day: dayName, contents: day[dayName] });
                        }
                    });
                    result.push({ week, activities });
                }
            });
            return result;
        };
    
        if (Array.isArray(schedule['Week 1'])) {
            const iteratedResults = iteratingObjects(schedule, orderDays);
    
            return (
                <div className='space-y-4'>
                    {iteratedResults.map((result, index) => (
                        <div key={index} className={`border-b border-gray-200 py-4`}>
                            <div className="flex justify-left items-center">
                                <button className="text-webDarker" onClick={() => toggleScheduleItem(result.week)}>
                                    {expandedSchedule[result.week] ? '−' : '+'}
                                </button>
                                <strong
                                    className={`ml-2 transition-transform duration-500 ${
                                        expandedSchedule[result.week] ? 'animate-slideOutRightShorter text-webDarker text-3xl' : 'text-xl text-text animate-slideInRightShorter'
                                    }`}
                                >
                                    {result.week}:
                                </strong>
                            </div>
                            {expandedSchedule[result.week] && (
                                <div className="pl-4 mt-4">
                                    {Array.isArray(result.activities) && result.activities.map((activity, idx) => (
                                        <div key={idx} className="mb-4">
                                            {/* Animate activity day */}
                                            <p className="font-semibold text-xl opacity-0 animate-fadeInUp">{activity.day}</p>
                                            <ul className="list-disc pl-5 space-y-2 mt-2">
                                                {/* Animate each activity detail */}
                                                {Array.isArray(activity.contents) && activity.contents.map((detail, subIdx) => (
                                                    <li key={subIdx} className={`opacity-0 animate-slideInRight animation-delay-${subIdx * 100}`}>
                                                        {detail}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className='space-y-4'>
                    {orderDays.map((day, index) => (
                        schedule[day] !== undefined && (
                            <div key={index} className={`border-b border-gray-200 py-4`}>
                                <div className="flex justify-left items-center">
                                    <button className="text-blue-500" onClick={() => toggleScheduleItem(day)}>
                                        {expandedSchedule[day] ? '−' : '+'}
                                    </button>
                                    <strong
                                        className={`ml-2 transition-transform duration-500 ${
                                            expandedSchedule[day] ? 'animate-slideOutRightShorter text-webDarker text-3xl' : 'text-xl text-text'
                                        }`}
                                    >
                                        {day}:
                                    </strong>
                                </div>
                                {expandedSchedule[day] && (
                                    <div className="pl-4 mt-4">
                                        <p className="font-normal text-lg opacity-0 animate-fadeInUp">{schedule[day]}</p>
                                    </div>
                                )}
                            </div>
                        )
                    ))}
                </div>
            );
        }
    };
    

    if (status === 'loading') {
        return (
            <div className='flex justify-center items-center h-screen text-webDarker'>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
        );
    }

    if (status === 'failed') {
        return <div className='text-red-500 text-center'>{error}</div>;
    }

    return (
        <div className='min-h-screen bg-white'>
            <div className="container mx-auto py-8 px-4">
                <button onClick={() => navigate(-1)} className="flex items-center text-text mb-8 hover:animate-zoomIn hover:text-webDarker">
                    <FontAwesomeIcon icon={faAngleLeft} className='mr-2'/> Back to all courses
                </button>
                {course ? (
                    <>
                        <div className="flex flex-col lg:flex-row justify-between items-start mb-8">
                            <div className='flex-1'>
                                <h1 className="text-4xl font-normal text-gray-800 mb-4">{course.title}</h1>
                                <p className='text-lg text-gray-600 mb-6'>{course.description}</p>
                                <div className='flex flex-wrap gap-4'>
                                    <span className='flex items-center text-gray-700'>
                                        <FontAwesomeIcon icon={faUserGraduate} className='mr-2 text-blue-500' />
                                        Level: {course.level}
                                    </span>
                                    <span className='flex items-center text-gray-700'>
                                        <FontAwesomeIcon icon={faCalendarAlt} className='mr-2 text-blue-500' />
                                        Duration: {course.duration}
                                    </span>
                                    <span className='flex items-center text-gray-700'>
                                        <FontAwesomeIcon icon={faCalendarCheck} className='mr-2 text-blue-500' />
                                        Begin on: {course.beginAt}
                                    </span>
                                    <span className='flex items-center text-gray-700'>
                                        <FontAwesomeIcon icon={faTag} className='mr-2 text-blue-500' />
                                        Price: 
                                        {course.isDiscounted ? (
                                            <>
                                                <span className="line-through ml-1">${course.price}</span>
                                                <span className="ml-2 text-blue-500 font-semibold">${course.discountedPrice}</span>
                                            </>
                                        ) : (
                                            <span className="ml-2">${course.price}</span>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-8 lg:mt-0 lg:ml-8">
                                <img src={getImage(course.imageUrl)} alt={course.title} className="w-full lg:w-[500px] rounded-lg object-cover"/>
                            </div>
                        </div>
                        <hr className="my-8" />
                        {isCourseExpired ? (
                            <div className="bg-red-100 text-red-700 p-4 rounded-lg text-center">
                                <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
                                <h2 className="inline-block">This course has expired and is no longer available for enrollment.</h2>
                            </div>
                        ) : (
                            <div className='flex flex-col lg:flex-row gap-8'>
                                <div className='flex-1'>
                                    <div className="mb-8">
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">About This Course</h2>
                                        <p className="text-gray-700 leading-relaxed">{course.about}</p>
                                    </div>

                                    <hr className="my-8" />

                                    <div className="mb-8">
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Topics Covered</h2>
                                        <div className="flex flex-wrap gap-2">
                                            {course.topicsCovered?.map((topic, index) => (
                                                <div key={index} className="bg-blue-500 text-white py-2 px-4 rounded-lg text-sm">
                                                    {topic}
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <hr className="my-8" />

                                    <div className="mb-8">
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Course Format</h2>
                                        <p className="text-gray-700 leading-relaxed">{course.courseFormat}</p>
                                    </div>

                                    <hr className="my-8" />

                                    <div className="mb-8">
                                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Sample Schedule</h2>
                                        {renderSchedule(course.sampleSchedule)}
                                    </div>
                                </div>
                                {/*the commented out section is the availability section where the user adds courses. Enable it when ready..*/}            
                                {!disableEnrollment&&(<div className='lg:w-[464px]'>
                                    <div className="bg-white p-6 rounded-lg shadow-lg">
                                        <h3 className="text-xl font-bold">Availability</h3>
                                        <p className="text-sm text-gray-500 mb-4">All time slots in Eastern Standard Zone (EST)</p>
                                        
                                        {course.sessions && course.sessions.map((session) => (
                                            <div key={session.id} className="mb-4">
                                                <div 
                                                    className="flex justify-between items-center cursor-pointer"
                                                    onClick={() => toggleSessionItem(session.id)}
                                                >
                                                    <div className="flex items-center transition-all">
                                                        <button className={`text-webDarker text-xl transform transition-transform duration-400 ${expandedSessions[session.id] ? 'rotate-90' : 'rotate-0'}`}>
                                                            {expandedSessions[session.id] ? '−' : '+'}
                                                        </button>
                                                        <strong className={`ml-2 transition-opacity duration-300 ease-in-out transform ${expandedSessions[session.id] ? 'opacity-100 translate-x-0' : 'opacity-40 translate-x-2'}`}>
                                                            {session.startDate} - {session.endDate}
                                                        </strong>
                                                    </div>
                                                </div>
                                                
                                                {expandedSessions[session.id] && (
                                                    <div className="mt-2 pl-8">
                                                        {Object.entries(session.times).map(([key, timeSlot]) => (
                                                            <div key={key} className="mb-2">
                                                                {isSessionExpired(session) ? (
                                                                    <p className="text-red-300 text-sm flex items-center">
                                                                        <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                                                        This session has expired
                                                                    </p>
                                                                ) : isRegisteredForSession(course.id, session.id) ? (
                                                                    <p className="text-green-300 text-sm flex items-center">
                                                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                                                        You already registered
                                                                    </p>
                                                                ) : (
                                                                    <div>
                                                                        <button 
                                                                            className="bg-transparent border-2 border-webDarker text-text px-4 py-2 rounded-full hover:bg-webDarker hover:text-white transition-colors duration-300"
                                                                            onClick={() => {
                                                                                handleEnrollNow(session, timeSlot);
                                                                                appendAddedSlot(timeSlot);                                                                            
                                                                            }}
                                                                            disabled={timeSlot.slots === 0}
                                                                        >
                                                                            {timeSlot.time}
                                                                        </button>
                                                                        {selectedTimes.length>0 && (
                                                                            <div className='mt-4 space-y-2'>
                                                                                {selectedTimes.map((time, index)=>(
                                                                                    <div key={index} className='text-text flex items-center transform translate-x-4 transition-all'>
                                                                                        <span className='text-webDarker font-medium'>Selected:  </span>{time}
                                                                                        <FontAwesomeIcon 
                                                                                            icon={faTimes} 
                                                                                            
                                                                                            className="cursor-pointer text-text hover:text-hoverColor hover:text-2xl transition-all duration-300 ease-out ml-2"

                                                                                            onClick={()=>handleRemoveSelectedTime(time)}/>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        
                                        <button 
                                            className={`w-full py-3 mt-4 text-white rounded-full ${isPurchaseDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`} 
                                            disabled={isPurchaseDisabled} 
                                            onClick={handlePurchase}
                                        >
                                            Add to Cart
                                        </button>
                                    </div>

                                </div>)}
                                {disableEnrollment&&(<div className='lg:w-[464px]'>
                                    <TemporarySignup course={course} />
                                </div>)}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex justify-center items-center h-screen">
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    </div>
                )}
            </div>
            <Footer />
            {showCartModal && (
                <CartModal
                    onClose={() => setShowCartModal(false)}
                    onCheckout={() => {
                        setShowCartModal(false);
                        navigate('/checkout');
                    }}
                    isVisible={showCartModal}
                />
            )}
            {showAddedToCartPopup && (
                <div className="fixed top-5 right-5 bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg">
                    Item added to cart!
                </div>
            )}
        </div>
    );
};

export default React.memo(CourseDetails);
