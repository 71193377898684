import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

import enpImage from '../../assets/img/enp2.png';
import webDevImage from '../../assets/img/WebDev2.png';
import webDevAiImage from '../../assets/img/WebDevAi2.png';
import peImage from '../../assets/img/promptEngineering2.png';
import pythonImage from '../../assets/img/python101.png';
import javaImage from '../../assets/img/Java101.png';
import javascriptImage from '../../assets/img/js101.png';
import HTMLcssImage from '../../assets/img/htmlandcss.png';

/**
 * Returns the image based on the provided image URL.
 *
 * @param {string} imageUrl - The URL of the image to be retrieved.
 * @return {object} The image object corresponding to the provided URL.
 */
const getImage = (imageUrl) => {
    if (imageUrl.includes('enpImage')) {
        return enpImage;
    } else if (imageUrl.includes('WDEImage')) {
        return webDevImage;
    } else if (imageUrl.includes('WDEAIImage')) {
        return webDevAiImage;
    } else if (imageUrl.includes('PEImage')) {
        return peImage;
    } else if (imageUrl.includes('PythonImage')) {
        return pythonImage;
    } else if (imageUrl.includes('JavaImage')) {
        return javaImage;
    } else if (imageUrl.includes('JavaScriptImage')) {
        return javascriptImage;
    } else if (imageUrl.includes('HTMLcssImage')) {
        return HTMLcssImage;
    }
};

/**
 * A React component that previews a course.
 *
 * @param {object} course - An object containing course information.
 * @return {JSX.Element} A JSX element representing the course preview.
 */
function CoursePreview({ course }) {
    console.log("course", course)
    const shortDescription = course.description.split(" ").slice(0, 20).join(" ") + "...";
    return (
        <Link to={`/courses/${course.id}`} className="block text-inherit">
            <div className="flex flex-col rounded-2xl overflow-hidden w-auto h-[457px] transition-transform transform hover:-translate-y-2 shadow-md bg-white">
                <img src={getImage(course.imageUrl)} alt={course.title} className="w-full h-[192px] object-cover" />
                <div className="p-4 flex flex-col justify-between flex-grow">
                    <h3 className="text-lg md:text-xl font-medium text-gray-900 mb-2">{course.title}</h3>
                    <div className="flex flex-col gap-2 text-gray-600 mb-4">
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faUserGraduate} className="mr-2 text-blue-600" />
                            {course?.level}
                        </div>
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-blue-600" />
                            {course?.duration}
                        </div>
                    </div>
                    <p className="text-sm text-gray-500 mb-4 line-clamp-3">{shortDescription}</p>
                    <div className="flex flex-wrap gap-2">
                        {course.category.map((cat, index) => (
                            <span key={index} className="bg-blue-600 text-white rounded-md px-2 py-1 text-xs">{cat}</span>
                        ))}
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default CoursePreview;
