/**
 * Firebase config
 * 
 * This configuration sets up and initializes the Firebase services used in this project.
 * 
 * - initializeApp: Initializes the Firebase application with the provided configuration.
 * - getAnalytics: Retrieves the Analytics instance for the Firebase app, allowing event logging and analysis.
 * - getFirestore: Initializes Firestore, a cloud-based NoSQL database for storing and retrieving data.
 * - getStorage: Initializes Firebase Storage for storing and serving user-generated content.
 * - getAuth: Initializes Firebase Authentication for managing user authentication and authorization.
 * - setPersistence: Configures the persistence settings for Firebase Authentication.
 * 
 */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error.code, error.message);
});


export { app, analytics, db, storage, auth };