import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';

/**
 * A functional React component that renders a thank you page for a job application.
 *
 * @return {JSX.Element} The JSX element representing the thank you page.
 */
const ThankYouPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div className="container mx-auto flex flex-col items-center justify-center flex-grow px-4 py-16 text-center">
        <h1 className="text-4xl font-medium mb-6">Thank You For Your Application!</h1>
        <p className="text-lg text-gray-700 mb-8">We appreciate your interest in joining our team. We will review your application and get back to you as soon as possible.</p>
        <Link
          to="/"
          className="px-6 py-3 bg-webDarker text-white rounded-lg hover:bg-hoverColor transition-colors"
        >
          Return Home
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(ThankYouPage);
