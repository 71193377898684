import React from 'react';
import newHeroImg from '../../assets/img/newHero.jpg';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
/**
 * A HeroSection component that renders a hero section with an image, title, and call-to-action buttons.
 *
 * @return {JSX.Element} The JSX element representing the hero section.
 */
function HeroSection() {
  const navigate = useNavigate();

  /**
   * Handles the click event for the 'Get Started' button.
   *
   * Logs a 'button_click' event with analytics and navigates to the '/courses' page.
   *
   * @return {void} No return value.
   */
  const handleGetStartedClick = () => {
    logEvent(analytics, 'button_click', { button_name: 'Get Started' });
    navigate('/courses');
  };

  /**
   * Handles the click event for the 'Discord' link.
   *
   * Logs a 'link_click' event with analytics, including the name of the clicked link.
   *
   * @return {void} No return value.
   */
  const handleDiscordClick = () => {
    logEvent(analytics, 'link_click', { link_name: 'Discord' });
  };

  return (
    <section className="flex flex-col md:flex-row items-center justify-around py-16 px-8 relative overflow-hidden h-auto md:h-[70vh]">
      {/* Apply order-1 for mobile and md:order-2 for larger screens */}
      <div className="relative z-10 w-full md:w-auto order-1 md:order-2 mb-8 md:mb-0">
        <img 
          src={newHeroImg}
          srcSet={`${newHeroImg} 300w, ${newHeroImg} 768w, ${newHeroImg} 1200w`}
          sizes="(max-width: 768px) 80vw, (max-width: 1200px) 50vw, 100vw"
          alt="Hero"
          className="w-full max-w-[700px] h-auto animate-slideInRight"
        />
      </div>
      {/* Apply order-2 for mobile and md:order-1 for larger screens */}
      <div className="max-w-xl text-left z-20 font-sans order-2 md:order-1">
        <h1 className="animate-slideInLeft text-[2rem] md:text-[3.3rem] text-webDarker font-bold mb-4 md:mb-6">
          <span className="text-accent font-bold ">Stand Out</span><br />
          with Skills that Get You Noticed
        </h1>
        <p className="animate-slideInLeft text-lg font-sans text-text mb-8">
        Worried you won’t stand out in college applications or job interviews? Gain the real-world skills top schools and companies demand. With hands-on projects and mentorship, you'll build a portfolio that proves your readiness to succeed.
        </p>
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <button 
            onClick={handleGetStartedClick}
            className="w-full md:w-auto px-8 py-4 bg-webDarker text-white rounded-full cursor-pointer text-lg transition-colors duration-300 ease-in-out hover:bg-[#7aa2e338] hover:text-webDarker"
          >
            Build Now
          </button>
          <a 
            href='https://discord.gg/EEKjMNrVQd'
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDiscordClick}
            className="w-full flex justify-center items-center md:w-auto px-8 py-4 bg-[#7aa2e338] text-webDarker rounded-full cursor-pointer text-lg transition-colors duration-300 ease-in-out hover:bg-webDarker hover:text-white"
          >
            {<span className='flex items-center'><FontAwesomeIcon className='mr-2' icon={faDiscord} />Join Us</span>}
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-full bg-white clip-path-ellipse-[100%_25%_at_50%_100%] z-0"></div>
    </section>
  );
}

export default HeroSection;