import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Renders a component that displays a message indicating that the order has been canceled.
 * After a delay of 1 second, the component redirects the user to the '/courses' page.
 *
 * @return {JSX.Element} The JSX element representing the canceled order message.
 */
const OrderCanceled = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/courses');
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-white">
      <div className="flex-grow flex items-center justify-center w-full px-4">
        <div className="bg-white rounded-2xl shadow-lg p-6 max-w-lg w-full text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-primary mb-4">Order Canceled</h1>
          <p className="text-gray-600 mb-4">Your order has been canceled. You can continue shopping or review your cart.</p>
          <p className="text-sm text-gray-500">Redirecting to courses...</p>
        </div>
      </div>
    </div>
  );
};

export default OrderCanceled;
