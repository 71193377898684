import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemFromCart } from '../../redux/slices/cartSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faShoppingCart, faTrash, faLock } from '@fortawesome/free-solid-svg-icons';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

/**
 * A React functional component that renders a cart modal.
 * It displays the cart items, subtotal, and a secure checkout button.
 * It also handles outside clicks to close the modal and item removal.
 *
 * @param {Object} props - component props
 * @param {function} props.onClose - callback function to close the modal
 * @param {function} props.onCheckout - callback function to handle checkout
 * @param {boolean} props.isVisible - flag to indicate if the modal is visible
 * @return {JSX.Element} the cart modal component
 */
function CartModal({ onClose, onCheckout, isVisible }) {
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart.items);
    const modalRef = useRef(null);
    const secureCheckoutRef = useRef(null); // Reference for Secure Checkout button

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const clickedInsideModal = modalRef.current && modalRef.current.contains(event.target);
            const clickedSecureCheckout = secureCheckoutRef.current && secureCheckoutRef.current.contains(event.target);
            const isTrashIcon = event.target.closest('.fa-trash'); // Check if the click is on the trash icon

            if (isVisible && !clickedInsideModal && !clickedSecureCheckout && !isTrashIcon) {
                logEvent(analytics, 'cart_close', { action: 'Clicked outside to close cart' });
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [isVisible, onClose]);

    const handleDecreaseQuantity = (item) => {
        dispatch(removeItemFromCart({ uniqueSessionTimeId: item.uniqueSessionTimeId }));
        logEvent(analytics, 'cart_item_removed', { course_name: item.courseName, item_id: item.uniqueSessionTimeId });
    };

    // Calculate subtotal
    const subtotal = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);

    return (
        <div className={`${isVisible ? 'right-0' : '-right-full'} fixed top-0 w-full sm:w-96 h-full bg-white shadow-xl flex flex-col transition-all duration-500 z-[1001] sm:rounded-l-3xl`}>
            <div ref={modalRef} className="flex flex-col justify-between h-full p-5 cart-content">
                <div className="flex-1 overflow-y-auto">
                    <div className="flex items-center justify-between mb-5">
                        <h2 className="text-lg font-bold">Your Cart</h2>
                        <FontAwesomeIcon icon={faAngleDoubleRight} className="text-xl cursor-pointer" onClick={onClose} />
                    </div>

                    {cartItems.length === 0 ? (
                        <div className="text-center py-20 text-gray-700">
                            <FontAwesomeIcon icon={faShoppingCart} size="3x" />
                            <p className="mt-5 text-lg">Oops, it's empty!</p>
                        </div>
                    ) : (
                        cartItems.map((item) => (
                            <div key={item.uniqueSessionTimeId} className="border-2 border-gray-200 rounded-3xl p-4 mb-4 bg-gray-100 shadow-md transform transition-transform duration-300 hover:-translate-y-1">
                                <div className="flex items-start">
                                    <div className="flex-1 flex flex-col justify-between">
                                        <div>
                                            <h3 className="text-lg font-bold text-gray-900 mb-1">{item.courseName}</h3>
                                            <p className="text-sm text-gray-600 mb-1">Starts on: {item.session.startDate} at {item.timeChosen}</p>
                                            <p className="text-sm text-gray-600 mb-3">Price: ${item.price}</p>
                                        </div>
                                        <div className="self-end mt-auto">
                                            <FontAwesomeIcon icon={faTrash} className="text-gray-400 cursor-pointer" onClick={() => handleDecreaseQuantity(item)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <div className="flex flex-col mt-5">
                    <div className="border-t pt-4 border-gray-200 mb-4 text-lg font-semibold">
                        Subtotal: ${subtotal.toFixed(2)}
                    </div>
                    <button 
                        ref={secureCheckoutRef}
                        onClick={(e) => {
                            e.stopPropagation();
                            onCheckout();
                            onClose();
                        }} 
                        className="bg-webDarker text-white py-3 px-6 rounded-lg shadow-md transition-all duration-300 hover:bg-hoverColor hover:shadow-lg uppercase font-medium tracking-wider"
                    >
                        <FontAwesomeIcon icon={faLock} className="mr-2" /> Secure Checkout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CartModal;
