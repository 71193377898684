import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * Renders a component that displays a list of signups for a user.
 *
 * @return {JSX.Element} The rendered component.
 */
const PreSignup = () => {
    const user = useSelector(state => state.user.user);
    const [usersSignup, setUsersSignup] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        /**
         * Fetches new comer data from Firestore and updates the usersSignup state.
         *
         * @return {void}
         */
        const fetchNewComers = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'newComers'));
                const newComersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const filteredUsersSignup = newComersData.filter(newComer => 
                    newComer?.email === user.email && newComer?.name
                );
                setUsersSignup(filteredUsersSignup);
            } catch (error) {
                console.error('Error fetching newComers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewComers();
    }, [user.email]);

    /**
     * Renders a component that displays a single signup card.
     *
     * @param {object} signup - An object containing information about the signup.
     * @param {string} signup.courseName - The name of the course.
     * @param {string} signup.date - The date of the signup.
     * @param {string} signup.courseId - The ID of the course.
     * @return {JSX.Element} The rendered signup card component.
     */
    const SignUpCard = ({ signup }) => {
        return (
            <div className="flex flex-col sm:flex-row bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                {/* Left Side: Course Name Section */}
                <div className="bg-webDarker text-white p-6 sm:w-1/3 flex flex-col justify-center">
                    <h2 className="text-xl font-medium mb-2 sm:mb-4 flex items-center">
                        <span className="whitespace-normal">{signup.courseName}</span>
                    </h2>
                </div>
    
                {/* Right Side: Date Section */}
                <div className="flex-1 p-6 flex flex-col justify-center">
                    <div className="text-sm sm:text-base text-text mb-1">
                        <strong>Signed Up Date:</strong>
                    </div>
                    <h3 className="text-lg font-medium text-textGray flex flex-col items-start">
                        <div className='flex items-center'>
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                            {new Date(signup.date).toLocaleDateString()}
                        </div>
                        <a href={`/courses/${signup.courseId}`} className="text-sm text-primary pt-2 hover:underline">
                            View course details
                        </a>
                    </h3>
                </div>
            </div>
        );
    };

    return (
        <div className="container mx-auto p-6">
            {loading ? (
                <div className="flex justify-center items-center py-16">
                    <FontAwesomeIcon icon={faSpinner} className="text-webDarker animate-spin text-4xl" />
                </div>
            ) : usersSignup.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-6 animate-blurIn">
                    {usersSignup.map(signup => (
                        <SignUpCard key={signup.id} signup={signup} />
                    ))}
                </div>
            ) : (
                <div className="text-center py-16">
                    <p className="text-lg text-textGray">No signups found for this user.</p>
                </div>
            )}
        </div>
    );
}

export default React.memo(PreSignup);
