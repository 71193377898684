// src/redux/slices/applicationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { storage, db } from '../../api/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp,getDoc, Timestamp } from 'firebase/firestore';

import emailjs from 'emailjs-com';

export const saveApplication = createAsyncThunk(
  'application/saveApplication',
  async (applicationData, { rejectWithValue }) => {
    try {
      console.log('Uploading resume...');
      
      // First, upload the resume if it exists
      if (applicationData.resume) {
        const storageRef = ref(storage, `resumes/${applicationData.resume.name}`);
        const snapshot = await uploadBytes(storageRef, applicationData.resume);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        applicationData.resumeUrl = downloadUrl;

        console.log('Resume uploaded successfully:', downloadUrl);

        // Remove the file object from data to be stored in Firestore
        delete applicationData.resume; 
      }

      console.log('Saving application data to Firestore...');

      // Create the application data object excluding timestamp
      const applicationDataToSave = {
        ...applicationData,
        timestamp: serverTimestamp(),
      };

      // Save the application data to Firestore
      const docRef = await addDoc(collection(db, "applications"), applicationDataToSave);

      const templateParams = {
        name: applicationData.name,
        position: applicationData.position,
        email: applicationData.email,
      };
      await emailjs.send('service_j9xjuih', 'template_ue55wdr', templateParams, 'SWdW9oauCRNUg4BAC');

      console.log('Application saved to Firestore with ID:', docRef.id);

      // Fetch the newly saved document to include in the returned payload
      const savedApplication = await getDoc(docRef);
      const savedApplicationData = savedApplication.data();

      if (savedApplicationData.timestamp instanceof Timestamp) {
        savedApplicationData.timestamp = savedApplicationData.timestamp.toDate().toISOString();
      }

      // Return the saved application data, including the Firestore document ID
      return { ...savedApplicationData, id: docRef.id };
    } catch (error) {
      console.error('Error saving application:', error);
      return rejectWithValue(error.message);
    }
  }
);


const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    applications: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveApplication.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveApplication.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.applications.push(action.payload);
      })
      .addCase(saveApplication.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default applicationSlice.reducer;
