import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../api/firebaseConfig';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';


export const fetchSingleCourse = createAsyncThunk(
  'courses/fetchSingleCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      const courseDocRef = doc(db, 'courses', courseId);
      const sessionsCollectionRef = collection(db, `courses/${courseId}/sessions`);

      // Start both requests simultaneously
      const courseDocPromise = getDoc(courseDocRef);
      const sessionsPromise = getDocs(sessionsCollectionRef);

      // Wait for both promises to resolve
      const [courseDocSnap, sessionsSnapshot] = await Promise.all([
        courseDocPromise,
        sessionsPromise,
      ]);

      if (!courseDocSnap.exists()) {
        return rejectWithValue('Course not found');
      }

      const courseData = courseDocSnap.data();
      const sessions = sessionsSnapshot.docs.map((doc) => {
        const sessionData = doc.data();
        const availability = sessionData.times
          ? Object.values(sessionData.times).reduce(
              (total, time) => total + (time.isAvailable ? time.slots : 0),
              0
            )
          : 0;

        return { id: doc.id, ...sessionData, availability };
      });

      return { id: courseDocSnap.id, ...courseData, sessions };
    } catch (error) {
      console.error('Error fetching single course: ', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async () => {
  const coursesSnapshot = await getDocs(collection(db, 'courses'));
  const courses = [];
  for (const courseDoc of coursesSnapshot.docs) {
    const courseData = courseDoc.data();
    const sessionsSnapshot = await getDocs(collection(db, `courses/${courseDoc.id}/sessions`));
    const sessions = sessionsSnapshot.docs.map(sessionDoc => {
      const sessionData = sessionDoc.data();
      let availability =0;

      if(sessionData.times){
        for (const key in sessionData.times){
          if(sessionData.times[key].isAvailable){
            availability+= sessionData.times[key].slots;
          }
        }
      }
      return {
        sessionId: sessionDoc.id,
        ...sessionData,
        availability,
      };
    });

    courses.push({
      id: courseDoc.id,
      ...courseData,
      sessions,
    });
  }

  return courses;
});

const courseSlice = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    course: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clearCourses: state => {
        state.courses = [];
    }
  },
      /**
       * Defines extra reducers for the Redux slice.
       *
       * @param {Object} builder - The Redux builder object.
       * @return {void}
       */
  extraReducers: (builder) => {
    builder
        .addCase(fetchCourses.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchCourses.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.courses = action.payload;
        })
        .addCase(fetchCourses.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchSingleCourse.pending, (state) => {
            state.status = 'loading';
            state.error = null; // Reset error on a new loading attempt
        })
        .addCase(fetchSingleCourse.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.course = action.payload; // Set the current course
        })
        .addCase(fetchSingleCourse.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload; // Use payload for the error message
            state.course = null; // Reset course to ensure clean state
        });
    }
});

// export const { clearCourses } = courseSlice.actions;
export default courseSlice.reducer;
export const { clearCourses } = courseSlice.actions;

