import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bannerImage from '../../assets/img/blogBanner.png';
import FeaturedPost from '../FeaturedPost';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';

/**
 * A React component that renders a blog template with a title, content, author, and featured posts.
 *
 * @param {object} props - The component props.
 * @param {string} props.id - The ID of the blog post.
 * @param {number} props.likes - The number of likes for the blog post.
 * @param {string} props.title - The title of the blog post.
 * @param {string} props.content - The content of the blog post.
 * @param {string} props.author - The author of the blog post.
 * @param {string} props.authorPhoto - The author's photo.
 * @param {string} props.createdAt - The date the blog post was created.
 * @param {string} props.imageUrl - The URL of the blog post's image.
 * @param {array} props.tags - The tags for the blog post.
 * @param {array} props.featuredPosts - The featured posts.
 * @return {JSX.Element} The rendered blog template.
 */
const BlogTemplate = ({ id, likes, title, content, author, authorPhoto, createdAt, imageUrl, tags, featuredPosts }) => {
  const [currentLikes, setCurrentLikes] = useState(likes);
  const bannerImageUrl = imageUrl || bannerImage;
  const formattedDate = createdAt ? new Date(createdAt).toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  }) : 'Unknown date';

  const handleLike = async() => {
    const newLikes = currentLikes+1;
    setCurrentLikes(newLikes);
    try{
        const blogRef = doc(db, 'blogs', id);
        await updateDoc(blogRef, {
            likes: newLikes,
        })
    }catch(error){
        console.error("Error updating likes: ", error);
    }
  };
  

  // const formatBlogContent = (content) => {
  //   let formattedContent = content;
  
  //   // Convert markdown-like syntax to HTML (if applicable)
  //   formattedContent = formattedContent
  //     .replace(/###\s*(.*)/g, '<h3>$1</h3>')
  //     .replace(/##\s*(.*)/g, '<h2>$1</h2>')
  //     .replace(/#\s*(.*)/g, '<h1>$1</h1>')
  //     .replace(/\n\s*\n/g, '<br><br>')  // Double line break for paragraphs
  //     .replace(/\n/g, '<br>');           // Single line break for line breaks
  
  //   // Clean up any unintended escaped newlines
  //   formattedContent = formattedContent.replace(/\\n\\n/g, '').replace(/\\n/g, '');
  
  //   return formattedContent;
  // };
  
  // const formattedResult = formatBlogContent(content);
  const shareUrl = window.location.href;

  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="mb-4">
        <Link to="/blogs" className="text-primary hover:underline">Blog</Link>
        <span className="mx-2 text-gray-400">&gt;</span>
        <span className="text-gray-500">{title}</span>
      </div>

      <article className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3">
          <div className="w-full h-64 sm:h-96 bg-cover bg-center rounded-lg shadow-md" style={{ backgroundImage: `url(${bannerImageUrl})` }}></div>

          <div className="mt-6">
            {/* <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2">{title}</h1> */}
            <div className="flex items-center text-gray-600 text-sm">
              <div className="flex items-center space-x-2">
                {/* {authorPhoto && <img src={authorPhoto} alt={author} className="w-8 h-8 rounded-full object-cover" />} */}
                <span>By {'Albuddy'}</span>
              </div>
              <time className="ml-4">{formattedDate}</time>
            </div>
            {tags && tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-4">
                {tags.map((tag, index) => (
                  <span key={index} className="bg-primary text-white text-xs font-medium px-2 py-1 rounded-full">{tag}</span>
                ))}
              </div>
            )}
            <div 
              className="
              prose 
              max-w-none 
              mt-6 
              prose-h1:text-3xl 
              prose-h1:font-medium 
              prose-h1:mb-0 
              prose-h1:mt-0
              prose-h2:text-2xl 
              prose-h2:font-medium 
              prose-h2:mb-0 
              prose-h2:mt-0
              prose-h3:text-2xl 
              prose-h3:font-medium 
              prose-h3:mb-0 
              prose-h3:mt-0
              prose-p:text-base 
              prose-p:leading-normal 
              prose-p:mb-0 
              prose-p:mt-0
              prose-a:text-webDarker 
              prose-a:underline 
              prose-img:rounded-lg 
              prose-img:shadow-lg 
              prose-blockquote:pl-4 
              prose-blockquote:border-l-4 
              prose-blockquote:border-gray-300 
              prose-blockquote:text-gray-700 
              prose-ul:list-disc 
              prose-ul:pl-5 
              prose-li:mb-0
              prose-li:mt-0
            "

              dangerouslySetInnerHTML={{ __html: content }} 
            />
            <div className="flex items-center mt-8 space-x-4">
              <button onClick={handleLike} className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-hoverColor transition-colors">
                Like ({currentLikes})
              </button>
              <div className="flex space-x-2">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>

        <aside className="lg:w-1/3 lg:ml-8 mt-12 lg:mt-0">
          <div className="bg-white p-6 shadow-md rounded-lg mb-8">
            <h2 className="text-xl font-semibold mb-4">Featured Posts</h2>
            {featuredPosts.map(post => (
              <FeaturedPost 
                key={post.id}
                id={post.id}
                tags={post.tags}
                title={post.title}
                imageUrl={post.imageUrl}
                author={post.author}
                createdAt={post.createdAt}
              />
            ))}
          </div>
        </aside>
      </article>
    </div>
  );
};

export default React.memo(BlogTemplate);
