import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import TrackerBar from './TrackerBar';

/**
 * A React functional component that renders a modal for a course.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.course - The course data.
 * @param {string} props.course.title - The course title.
 * @param {string} props.course.description - The course description.
 * @param {string} props.course.instructor - The course instructor.
 * @param {string} props.course.startDate - The course start date.
 * @param {string} props.course.endDate - The course end date.
 * @param {string} props.course.timeSlot - The course time slot.
 * @param {Object} props.course.sampleSchedule - The course sample schedule.
 * @param {string} props.course.meetingUrl - The course meeting URL.
 * @param {Function} props.onClose - The callback function to close the modal.
 * @return {JSX.Element} The JSX element representing the course modal.
 */
const CourseModal = ({ course, onClose }) => {
  if (!course) return null;

  const parseDate = (dateString, timeString) => {
    const now = new Date();
    const date = new Date(`${dateString} ${now.getFullYear()} ${timeString}`);
    return date;
  };

  /**
   * Converts a time string in 12-hour format to 24-hour format.
   *
   * @param {string} time - The time string to convert, in the format 'HH:MM am/pm'.
   * @return {string} The converted time string in 24-hour format, in the format 'HH:MM'.
   */
  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(/(am|pm)/i);
    let [hours, minutes] = timePart.split(':');
    if (modifier.toLowerCase() === 'pm' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier.toLowerCase() === 'am' && hours === '12') {
      hours = '00';
    }
    return `${hours}:${minutes || '00'}`;
  };

/**
 * Parses a time slot string and returns the start and end times in 24-hour format.
 *
 * @param {string} timeSlot - The time slot string to parse.
 * @return {Object} An object containing the start and end times in 24-hour format.
 */
  const parseTimeSlot = (timeSlot) => {
    if (!timeSlot) return { startTime: null, endTime: null };
    const [start, end] = timeSlot.split(' - ');
    const startTime = convertTo24HourFormat(start.trim());
    const endTime = convertTo24HourFormat(end.trim());
    return { startTime, endTime };
  };

  const startDate = course.startDate;
  const endDate = course.endDate;
  const theParseTimeSlot = parseTimeSlot(course.timeSlot);
  const startTime = theParseTimeSlot.startTime;
  const endTime = theParseTimeSlot.endTime;
  const startDateObj = parseDate(startDate, startTime);
  const endDateObj = parseDate(endDate, endTime);
  const now = new Date();
  const isCourseExpired = endDateObj < now;

  /**
   * Renders a schedule based on the provided schedule object.
   *
   * @param {Object} schedule - The schedule object to render, where each key is a week and each value is an object containing days and activities.
   * @return {JSX.Element} The rendered schedule.
   */
  const renderSchedule = (schedule) => {
    // Sort weeks based on their number (e.g., "Week 1", "Week 2", etc.)
    const sortedWeeks = Object.keys(schedule).sort((a, b) => {
      const weekA = parseInt(a.split(' ')[1], 10);
      const weekB = parseInt(b.split(' ')[1], 10);
      return weekA - weekB;
    });

    /**
     * Renders activities based on the provided activities object.
     *
     * @param {Array|string} activities - The activities object to render, which can be an array of activities or a single activity string.
     * @return {JSX.Element|null} The rendered activities in JSX format, or null if activities is not an array or string.
     */
    const renderActivities = (activities) => {
      if (Array.isArray(activities)) {
        return (
          <ul className="list-disc list-inside space-y-1 mt-2">
            {activities.map((activity, index) => (
              <li key={index} className="text-gray-600">{activity}</li>
            ))}
          </ul>
        );
      } else if (typeof activities === 'string') {
        return <p className="text-gray-600 mt-2">{activities}</p>;
      } else {
        return null;
      }
    };

    return sortedWeeks.map((week, weekIndex) => (
      <div key={weekIndex} className="mb-6 animate-fadeInUp">
        <h3 className="text-lg font-semibold text-gray-700">{week}</h3>
        {Array.isArray(schedule[week]) ? (
          schedule[week].map((dayObj, dayIndex) => (
            <div key={dayIndex} className="ml-4">
              {Object.keys(dayObj).map((day) => (
                <div key={day} className="mt-2">
                  <p className="font-medium text-gray-800">{day}</p>
                  {renderActivities(dayObj[day])}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="ml-4">
            {renderActivities(schedule[week])}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out"
      onClick={onClose}
    >
      <div
        className="relative bg-white w-11/12 max-w-3xl mx-auto p-8 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] transform transition-transform duration-300 ease-out scale-95 hover:scale-100"
        onClick={(e) => e.stopPropagation()}
      >
        {isCourseExpired ? (
          <div className="flex flex-col items-center text-center animate-pulse">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-400 text-4xl mb-4" />
            <h2 className="text-xl font-medium text-textGray">Your course has expired</h2>
          </div>
        ) : (
          <>
            <h2 className="text-3xl font-medium text-text mb-6">{course.title}</h2>
            <p className="text-textGray text-lg mb-6">{course.description}</p>
            <p className="text-gray-600 font-medium text-lg mb-4">
              <strong>Instructor:</strong> {course.instructor}
            </p>
            <div className='m-8'>
                <TrackerBar startDate={startDateObj} endDate={endDateObj} sampleSchedule={course.sampleSchedule} />
            </div>
            <div className="mb-8">
              <h3 className="text-xl font-medium text-text mb-4">Sample Schedule</h3>
              <div>{renderSchedule(course.sampleSchedule)}</div>
            </div>
            <div className="sticky bottom-4 right-4 flex justify-end">
              <a
                href={course.meetingUrl}
                className="text-white bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full text-lg shadow-md transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Meeting
              </a>
            </div>
          </>
        )}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-2xl transition-colors duration-200"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default CourseModal;
