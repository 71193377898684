
/**
 * Creates a Stripe checkout session for the provided cart items and user.
 *
 * @param {Array} cartItems - An array of items in the user's cart to be purchased.
 * @param {string} userId - The unique identifier for the user making the purchase.
 * @param {string} promoCode - An optional promotional code to be applied to the purchase.
 * @return {Object} The response data from the Stripe checkout session creation.
 */
export const createCheckoutSession = async (cartItems, userId, promoCode) => {
    const functionsUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;
    try {
      const response = await fetch(`${functionsUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartItems, userId, promoCode }),
      });
  
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to create Stripe checkout session');
      }

      return data;
      
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  };



  export const requestRefund = async (paymentIntentId) => {
    // This function has to take the paymentIntendId stored in the user orderHistory.
    const functionsUrlRefund = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_REFUND_TES;
    try {
        const response = await fetch(`${functionsUrlRefund}/refund`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentIntentId }),
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Refund failed');
        }

        return data;
    } catch (error) {
        console.error('Error processing refund:', error);
        throw error;
    }
};