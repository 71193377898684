import React from 'react';
import { Link } from 'react-router-dom';
import defaultBanner from '../../assets/img/blogBanner.png';

/**
 * A React component that renders a preview of a blog post.
 *
 * @param {object} props - The component props.
 * @param {string} props.id - The ID of the blog post.
 * @param {string} props.title - The title of the blog post.
 * @param {string} props.summary - A brief summary of the blog post.
 * @param {string} props.imageUrl - The URL of the blog post's image.
 * @param {string[]} props.tags - An array of tags associated with the blog post.
 * @param {string} props.createdAt - The date and time the blog post was created.
 * @param {string} props.author - The author of the blog post.
 * @param {boolean} props.large - Whether the preview should be displayed in a large format.
 * @return {JSX.Element} The rendered blog post preview.
 */
const BlogPreview = ({ id, title, summary, imageUrl, tags, createdAt, author, large }) => {
  const bannerImageUrl = imageUrl || defaultBanner;
  const formattedDateTime = createdAt
    ? new Date(createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'Date unknown';

  return (
    <Link 
      to={`/blogs/${id}`} 
      className="flex flex-col sm:flex-row items-start sm:items-start gap-4 sm:gap-6 py-6 sm:py-8 border-b border-gray-200 hover:bg-gray-50 transition-all duration-300"
    >
      <img 
        src={bannerImageUrl} 
        alt={title} 
        className="w-[343px] h-[192px] min-w-[343px] min-h-[192px] object-cover rounded-lg shadow-sm"
      />
      <div className="flex flex-col justify-between space-y-4 sm:space-y-6">
        <div className="text-gray-500 text-xs sm:text-sm">
          {`By Albuddy`} {formattedDateTime && `| ${formattedDateTime}`}
        </div>
        <h2 className="text-lg sm:text-2xl font-semibold text-gray-800 leading-snug hover:text-webDarker transition-colors duration-300">
          {title}
        </h2>
        <p className="text-gray-600 text-sm sm:text-base line-clamp-3">
          {summary}
        </p>
        {tags?.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {tags.map((tag, index) => (
              <span 
                key={index} 
                className="inline-block bg-webDarker text-white text-xs sm:text-sm font-medium px-3 py-1 rounded-full"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};

export default BlogPreview;
