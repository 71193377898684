import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * A functional component that displays a success message after a successful order.
 * It redirects the user to the portal page after a short delay.
 *
 * @return {JSX.Element} The JSX element representing the success message.
 */
const OrderSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/portal');
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-white">
      <div className="flex-grow flex items-center justify-center w-full px-4">
        <div className="bg-white rounded-2xl shadow-lg p-6 max-w-lg w-full text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-primary mb-4">Order Placed Successfully!</h1>
          <p className="text-gray-600 mb-4">Thank you for your purchase. You will receive an email confirmation and your course will be registered to your account soon.</p>
          <p className="text-sm text-gray-500">Redirecting to portal...</p>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
