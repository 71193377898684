import React from 'react';

/**
 * A React functional component that renders a course card with title, instructor, start date, end date, and a button to view course details.
 *
 * @param {string} title - The title of the course.
 * @param {string} instructor - The instructor of the course.
 * @param {string} startDate - The start date of the course.
 * @param {string} endDate - The end date of the course.
 * @param {function} onClick - A callback function to handle the click event on the view course details button.
 * @return {JSX.Element} The JSX element representing the course card.
 */
const CourseCard = ({ title, instructor, startDate, endDate, onClick }) => {
    return (
        <div className="flex flex-col sm:flex-row bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            {/* Left Side: Title Section */}
            <div className="bg-webDarker text-white p-4 sm:w-1/3 flex flex-col justify-between">
                <h2 className="text-lg font-bold mb-4 sm:mb-6">{title}</h2>
                <button 
                    onClick={onClick} 
                    className="text-white text-sm mt-auto underline border-none py-2 transition-transform transform hover:translate-x-1"
                >
                    View course details &rarr;
                </button>
            </div>

            {/* Right Side: Details Section */}
            <div className="flex-1 p-6 relative">
                <div className="uppercase mb-2 text-sm sm:text-base">
                    <strong>Instructor:</strong>
                </div>
                <h3 className="text-xl font-medium text-gray-800">{instructor}</h3>
                <div className="mt-4">
                    <p className="text-sm sm:text-base text-gray-600">
                        <strong>Course Duration:</strong> {startDate} - {endDate}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CourseCard;
