import { useState } from "react";
import {AnimatePresence, motion} from 'framer-motion';

const FlyoutLink = ({ children, href, FlyoutContent }) => {
    const [open, setOpen] = useState(false);
    const showFlyout = open && FlyoutContent;
    return(
        <div 
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className='relative h-fit w-fit'>
                <a href={href} className="text-base text-textGray hover:text-webDarker">
                    {children}
                    <span
                        style={{transform: showFlyout? "scaleX(1)":"scaleX(0)"}} 
                        className=" 
                                    absolute -bottom-2 -left-2 -right-2 h-1
                                    origin-left rounded-full bg-webDarker
                                    transition-transform duration-300 east-out
                                "
                    />
                </a>
                <AnimatePresence>    
                    {showFlyout && 
                        <motion.div initial={{opacity: 0, y:15}}
                                    animate={{opacity: 1, y:0}} 
                                    exit={{opacity: 0, y:15}} 
                                    style={{translateX: "-50%"}} 
                                    transition={{duration:0.3, ease: 'easeOut'}} 
                                    className="absolute left-1/2 top-12 bg-white rounded-md text-text shadow-3d">
                                        <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent"></div>
                                        <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white rounded-md"></div>
                                        <FlyoutContent/>
                        </motion.div>
                    }
                </AnimatePresence>       
        </div>
    );
}

export default FlyoutLink;

