
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * A React functional component that displays a discount message.
 *
 * @return {JSX.Element} A JSX element representing the discount message.
 */
const DiscountMessage = () => {
  const navigate = useNavigate();

  /**
   * Handles navigation to the courses page.
   *
   * @return {void} No return value.
   */
  const handleNavigation = () => {
    navigate('/courses');
  };

  return (
    <div className="fixed left-0 right-0 min-w-full bg-webDarker text-white text-center py-2 animate-slideDown">
      <span>
        30% OFF on all courses! Use code{' '}
        <span
          onClick={handleNavigation}
          className="font-extrabold underline cursor-pointer"
        >
          Albuddylaunch24
        </span>
      </span>
    </div>
  );
};

export default DiscountMessage;


// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
// const DiscountMessage = () => {
//   const [isExpanded, setIsExpanded] = useState(true);
//   const navigate = useNavigate();
//   const toggleExpand = () => {
//     setIsExpanded(true);
//   };
//   const toggleNavigation = () => {
//     navigate('/courses');
//   };

//   return (
//     <>
//       {/* Collapsed State */}
//       {!isExpanded && (
//         <div
//           className={`fixed left-0 right-0 z-50 flex items-center justify-center cursor-pointer animate-slideDown`}
//           style={{ top: '-6px' }}
//           onClick={toggleExpand}
//         >
//           <FontAwesomeIcon
//             icon={faChevronDown}
//             className={`text-xl text-webDarker animate-pulse`}
//           />
//         </div>
//       )}

//       {/* Expanded State */}
//       {isExpanded && (
//         <div
//           className={`fixed top-0 left-0 right-0 z-50 flex flex-col items-center bg-webDarker text-white text-center px-4 py-2 animate-slideDown`}
//         >
//           <span className={`cursor-pointer `} onClick={toggleExpand}>
//             30% OFF on all courses! Use code <span onClick={toggleNavigation} className='font-extrabold ml-2 underline text-white'>Albuddylaunch24</span>
//           </span>
//           {/* <FontAwesomeIcon
//             icon={faChevronDown}
//             onClick={toggleExpand}
//             className={`cursor-pointer mt-2 text-lg rotate-180`}
//           /> */}
//         </div>
//       )}
//     </>
//   );
// };

// export default DiscountMessage;


// //fixed left-0 right-0 z-50 flex items-center justify-center cursor-pointer animate-slideDown